<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item>
      <el-breadcrumb-item>活动列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input
            placeholder="请输入内容"
            v-model="searchFeild"
            clearable
            @clear="getUserList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-date-picker
            v-model="dateInfo"
            align="right"
            type="date"
            placeholder="选择日期"
            @change="getUserList"
            value-format="yyyy-M-d"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="exportList">导出记录</el-button>
        </el-col>
      </el-row>
      <el-table :data="userList" border stripe>
        <!-- <el-table-column type="index" label="#"></el-table-column> -->
        <el-table-column prop="puweihao" label="编号"></el-table-column>
        <el-table-column prop="date" label="上传时间"></el-table-column>
        <el-table-column
          prop="shigongxiangmu"
          label="施工项目"
        ></el-table-column>
        <el-table-column
          prop="zhenggaixiangmu"
          label="整改项目"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row._id)"
            ></el-button>
            <el-button
              type="warning"
              icon="el-icon-setting"
              size="mini"
            ></el-button>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除用户"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 25, 50]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import exportExcel from "js-table2excel";
export default {
  data() {
    return {
      userList: [],
      total: 0,
      pageSize: 10,
      pageNum: 1,
      searchFeild: "",
      addDialogVisible: false,
      addForm: { name: "", username: "", password: "", auth: "" },
      addFormRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        password: [
          { required: true, message: "请输入初始密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "初始密码长度需在6-15个字符之间",
            trigger: "blur",
          },
        ],
      },
      loading: false,
      value: "",
      dateInfo: "",
      tempFileURL: "",
      fileList: "",
      finalList: [],
    };
  },
  async created() {
    const loginState = this.$cloudbase.auth().hasLoginState();
    if (loginState) {
      // 登录态有效
      console.log("登录状态有效");
      this.dateInfo = this.GetDateStr(0);
      this.getUserList(this.date);
    } else {
      console.log("登录状态无效");
      this.$router.push("/login");
    }
  },

  methods: {
    handleSizeChange(newSize) {
      console.log("每页显示数量", newSize);
      this.pageSize = newSize;
      this.getUserList();
    },

    handleCurrentChange(newPage) {
      console.log("页码", newPage);
      this.pageNum = newPage;
      this.getUserList();
    },

    async getUserList() {
      const db = this.$cloudbase.database();
      const _ = db.command;
      await this.$cloudbase
        .database()
        .collection("image")
        .limit(this.pageSize)
        .skip(this.pageSize * (this.pageNum - 1))
        .orderBy("createTime", "desc")
        .get()
        .then((res) => {
          console.log("巡场列表：", res.data);
          this.userList = res.data;
        });
      await this.$cloudbase
        .database()
        .collection("image")
        .count()
        .then((res) => {
          console.log("巡场列表长度：", res.total);
          this.total = res.total;
        });
    },

    GetDateStr(AddDayCount) {
      var dd = new Date();
      dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1; //获取当前月份的日期
      var d = dd.getDate();
      return y + "-" + m + "-" + d;
    },

    search() {
      console.log("-----------", this.searchFeild);
      const db = this.$cloudbase.database();
      const _ = db.command;
      this.$cloudbase
        .database()
        .collection("image")
        .limit(this.pageSize)
        .skip(this.pageSize * (this.pageNum - 1))
        .where(
          _.or([
            {
              puweihao: db.RegExp({
                regexp: this.searchFeild,
                options: "i",
              }),
            },
            {
              shigongxiangmu: db.RegExp({
                regexp: this.searchFeild,
                options: "i",
              }),
            },
          ])
        )
        .get()
        .then((res) => {
          console.log("用户列表：", res.data);
          this.userList = res.data;
        });
    },

    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },

    tableRowClassName(row) {
      if (row.status == "cancel") {
        console.log("warning");
        return "warning-row";
      }
    },

    async exportList() {
      for (let i in this.userList) {
        this.fileIDs = this.userList[i].fileIDs;
        this.username = this.userList[i].username;
        this.shigongxiangmu = this.userList[i].shigongxiangmu;
        this.zhenggaixiangmu = this.userList[i].zhenggaixiangmu;
        this.puweihao = this.userList[i].puweihao;
        this.date = this.userList[i].date;
        await this.$cloudbase
          .getTempFileURL({
            fileList: this.fileIDs,
          })
          .then((res) => {
            let urlList = res.fileList;
            this.pic1 = urlList[0].download_url;
            // console.log(this.tempFileURL);
            this.finalList.push({
              puweihao: this.puweihao,
              date: this.date,
              shigongxiangmu: this.shigongxiangmu,
              zhenggaixiangmu: this.zhenggaixiangmu,
              name: this.username,
              pic: this.pic1,
            });
          });
      }
      console.log(this.finalList);
      const column = [
        {
          title: "铺位号",
          key: "puweihao",
          type: "text",
          width: 310,
          height: 340,
        },
        {
          title: "上传时间",
          key: "date",
          type: "text",
        },
        {
          title: "施工项目",
          key: "shigongxiangmu",
          type: "text",
        },
        {
          title: "整改项目",
          key: "zhenggaixiangmu",
          type: "text",
        },
        {
          title: "上传人",
          key: "name",
          type: "text",
        },
        {
          title: "Pic",
          key: "pic",
          type: "image",
          width: 100,
          height: 130,
        },
      ];
      /** column数据的说明 */
      // 1.title为列名
      // 2.key为data数据每个对象对应的key
      // 3.若为图片格式, 需要加type为image的说明,并且可以设置图片的宽高
      const excelName = "巡检记录";
      exportExcel(column, this.finalList, excelName);
    },
  },
};
</script>

<style lang="less" scoped>
.el-table .warning-row {
  background: red;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>