<template>
  <div>
    <div class="card-panel-text">整改数量排行</div>
    <dv-scroll-ranking-board
      :config="config"
      style="width: 500px; height: 300px"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        data: []
      },
    };
  },

  async created() {},

  async mounted() {
    await this.getUncomplete();
    this.config = {
      data: this.dataList,
      unit: "个",
      sort: true,
      rowNum: 6,
    };
  },

  methods: {
    async getUncomplete() {
      await this.$app
        .callFunction({
          name: "getInspectionCount",
        })
        .then((res) => {
          this.dataList = res.result.list;
          let list = [];
          for (let i = 0, len = this.dataList.length; i < len; i++) {
            let event1 = {};
            if (
              this.dataList[i]._id == "懒洋洋" ||
              this.dataList[i]._id == "广州十大杰出青年苏乞儿" ||
              this.dataList[i]._id == "kإغلاق" ||
              this.dataList[i]._id == "Monster" ||
              this.dataList[i]._id == null ||
              this.dataList[i]._id == "多年以后" ||
              this.dataList[i]._id == "舒跃春" ||
              this.dataList[i]._id == "吴夏" ||
              this.dataList[i]._id == "张瀚" ||
              this.dataList[i]._id == "林彦肖" ||
              this.dataList[i]._id == "王婧娴"
            ) {
              console.log("--------");
            } else {
              event1["name"] = this.dataList[i]._id;
              event1["value"] = this.dataList[i].value;
              event1 = JSON.parse(JSON.stringify(event1));
              list.push(event1);
            }
          }
          this.dataList = list;
        })
        .catch((err) => {
          this.$message.error("发送失败！");
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less">
.card-panel-text {
  line-height: 18px;
  color: rgb(0, 0, 0);
  font-size: 20px;
  margin-bottom: 12px;
  align-items: center;
}

body {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: black;
}

.info-name{
  color: black;
}
.ranking-value{
  color: black;
}
</style>