<template>
  <div>
    <div id="container"></div>
  </div>

</template>
 

<script>
import * as THREE from "three";
import { OBJLoader, MTLLoader } from "three-obj-mtl-loader";
const OrbitControls = require("three-orbit-controls")(THREE);
import { CSS2DRenderer, CSS2DObject } from "three-css2drenderer";

export default {
  name: "ThreeTest",
  data() {
    return {
      scene: "",
      light: "",
      camera: "",
      controls: "",
      renderer: "",
      labelRenderer: "",
    };
  },
  methods: {
    //初始化three.js相关内容
    init() {
      this.scene = new THREE.Scene();
      this.scene.add(new THREE.AmbientLight(0x444444, 3.5)); //环境光
      this.light = new THREE.DirectionalLight(0x444444, 0.45); //从正上方（不是位置）照射过来的平行光，0.45的强度
      this.light.position.set(60, 30, 0);
      this.light.position.multiplyScalar(0.3);
      this.light.castShadow = true;
      this.scene.add(this.light);

      //初始化相机
      this.camera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        1,
        1000
      );

      this.camera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      this.camera.position.set(0, 150, 150);
      this.camera.lookAt(new THREE.Vector3(0, 0, 0));
      //   this.camera.position.set(10, 10, 10);
      //   this.camera.lookAt(this.scene.position);
      //初始化控制器
      this.controls = new OrbitControls(this.camera);
      this.controls.target.set(0, 0, 0);
      this.controls.minDistance = 80;
      this.controls.maxDistance = 400;
      this.controls.maxPolarAngle = Math.PI / 3;
      this.controls.update();

    //   const earthDiv = document.createElement("div");
    //   earthDiv.className = "label";
    //   earthDiv.textContent = "Earth";
    //   earthDiv.style.marginTop = "-1em";
    //   const earthLabel = new THREE.CSS2DObject(earthDiv);
    //   earthLabel.position.set(0, 10, 0);
    //   earth.add(earthLabel);

    //   const moonDiv = document.createElement("div");
    //   moonDiv.className = "label";
    //   moonDiv.textContent = "Moon";
    //   moonDiv.style.marginTop = "-1em";
    //   const moonLabel = new THREE.CSS2DObject(moonDiv);
    //   moonLabel.position.set(0, 0, 0);
    //   moon.add(moonLabel);

      //渲染
      this.renderer = new THREE.WebGLRenderer({
        alpha: true,
      });
      this.renderer.setClearColor(0x000000, 0);
      this.renderer.setPixelRatio(window.devicePixelRatio); //为了兼容高清屏幕
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.shadowMapEnabled = true;

    //   this.labelRenderer = new CSS2DRenderer();
    //   this.labelRenderer.setSize(window.innerWidth, window.innerHeight);
    //   this.labelRenderer.domElement.style.position = "absolute";
    //   this.labelRenderer.domElement.style.top = "0px";
    //   document.body.appendChild(labelRenderer.domElement);

      const container = document.getElementById("container");
      container.appendChild(this.renderer.domElement);
      window.addEventListener("resize", this.onWindowResize, false); //添加窗口监听事件（resize-onresize即窗口或框架被重新调整大小）
    },
    //窗口监听函数
    onWindowResize() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.render();
    //   this.labelRenderer.render( scene, camera );
    },
    render() {
      this.renderer.render(this.scene, this.camera);
    },
    //外部模型加载函数
    loadObj() {
      //包含材质
      new MTLLoader().setPath("static/models/").load("L4.mtl", (materials) => {
        console.log("materials", materials);
        materials.preload();
        new OBJLoader()
          .setMaterials(materials)
          .setPath("static/models/")
          .load("L4.obj", (obj) => {
            console.log("obj", obj);
            obj.scale.set(0.06, 0.06, 0.06);
            obj.position.set(0, 0, 0);
            this.scene.add(obj);
          });
      });
    },
  },
  mounted() {
    this.init();
    this.loadObj();
    this.animate();
  },
};
</script>
<style scoped>
#container {
  width: 95%;
  margin: 0 auto;
  height: 95%;
  overflow: hidden;
}
.label {
  color: #fff;
  font-family: sans-serif;
  padding: 2px;
  background: rgba(0, 0, 0, 0.6);
}
</style>