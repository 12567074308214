<template>
  <el-container class="home-container">
    <el-header height="53px">
      <div>
        <img class="tubiao" src="../assets/tubiao.png" alt="" />
        <span>工程组管理系统</span>
      </div>
      <el-button type="danger" @click="logout">登出</el-button>
    </el-header>
    <el-container>
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <el-menu
          background-color="#1E222D"
          text-color="#fff"
          active-text-color="#409EFF"
          :router="true"
          :unique-opened="true"
          :collapse="isCollapse"
          :collapse-transition="false"
          :default-active="activePath"
        >
          <el-menu-item index="/welcome">
            <i class="el-icon-s-help"></i>
            <span slot="title" @click="saveNavState('/welcome')">首页</span>
          </el-menu-item>
                    <el-menu-item index="/map">
            <i class="el-icon-map-location"></i>
            <span slot="title" @click="saveNavState('/map')">楼层平面图</span>
          </el-menu-item>
          <el-submenu
            :index="item.id + ''"
            v-for="item in menuList"
            :key="item.id"
          >
            <template slot="title">
              <i :class="iconsObj[item.id]"></i>
              <span>{{ item.authName }}</span>
            </template>
            <el-menu-item
              :index="subItem.path"
              v-for="subItem in item.children"
              :key="subItem.id"
              @click="saveNavState(subItem.path)"
            >
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>{{ subItem.authName }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          authName: "巡场记录",
          id: "aa123",
          path: "inspection",
          children: [
            { authName: "全部记录", id: "aaa123", path: "/logs" },
            { authName: "待消项记录", id: "aaa456", path: "/inspection" },
          ],
        },
        {
          authName: "用户管理",
          id: "bb123",
          path: "users",
          children: [
            { authName: "内部用户列表", id: "bbb123", path: "/users" },
            { authName: "租户用户列表", id: "bbb456", path: "/guest_user" },
          ],
        },
        {
          authName: "资料",
          id: "cc123",
          path: "files",
          children: [
            { authName: "店铺信息", id: "ccc123", path: "/files" },
            { authName: "统计图表", id: "ccc456", path: "/table" },
          ],
        },
        {
          authName: "验收管理",
          id: "dd123",
          path: "reservation",
          children: [
            { authName: "验收记录", id: "ddd123", path: "/reservation" },
          ],
        },
      ],
      iconsObj: {
        aa123: "el-icon-s-order",
        bb123: "el-icon-user-solid",
        cc123: "el-icon-s-operation",
        dd123: "el-icon-s-grid",
      },
      isCollapse: false,
      activePath: "",
    };
  },
  async created() {
    this.activePath = window.sessionStorage.getItem("activePath");
    const loginState = this.$cloudbase.auth().hasLoginState();
    if (loginState) {
      // 登录态有效
      console.log("登录状态有效");
      // this.getMenuList();
    } else {
      console.log("登录状态无效");
      this.$router.push("/login");
    }
  },
  methods: {
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },

    async getMenuList() {
      const db = this.$cloudbase.database();
      const _ = db.command;
      await this.$cloudbase
        .database()
        .collection("menuList")
        .get()
        .then((res) => {
          console.log("左侧菜单：", res);
          this.menuList = res.data;
        });
    },

    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },

    saveNavState(activePath) {
      window.sessionStorage.setItem("activePath", activePath);
      this.activePath = activePath;
    },
  },
};
</script>

<style lang="less" scoped>
.el-header {
  background-color: #262f3e;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 15px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
  }
}

.el-aside {
  background-color: #1e222d;
  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: white;
}

.home-container {
  height: 100%;
}

.tubiao {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin-left: 15px;
}

.toggle-button {
  background-color: #1e222d;
  font-size: 15px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
</style>