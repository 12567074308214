<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>资料</el-breadcrumb-item>
      <el-breadcrumb-item>店铺信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row :gutter="10">
        <el-col :span="5">
          <el-input
            placeholder="请输入搜索关键词"
            v-model="searchFeild"
            clearable
            @clear="getUserList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="addDialogVisible = true"
            >添 加</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button
            type="primary"
            @click="exportList"
            style="margin-left: -50px"
            >导 出</el-button
          >
        </el-col>
        <el-col :span="2">
          <div>
            <el-checkbox-group
              v-model="checkboxGroup1"
              style="margin-top: 10px"
            >
              <el-checkbox
                v-for="filterOption in filterOptions"
                :label="filterOption"
                :key="filterOption"
                @change="filterChange"
                >{{ filterOption }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-col>
        <el-col :span="8">
          <div>
            <el-checkbox-group
              v-model="checkboxGroup2"
              style="margin-top: 10px"
            >
              <el-checkbox
                v-for="filterOption_1 in filterOptions_1"
                :label="filterOption_1"
                :key="filterOption_1"
                @change="filterChange_1"
                >{{ filterOption_1 }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-col>
      </el-row>
      <el-table :data="userList" border stripe align="center" fixed="right">
        <el-table-column
          type="index"
          label="#"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="Shop"
          label="编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="Brand"
          label="品牌"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="area"
          label="面积(㎡)"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="allowed"
          label="可施工项目"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="condition"
          label="状态"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="construct"
          label="施工图审核次数"
          width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="openInspection"
          label="开业检查"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="info"
          label="备注"
          align="center"
        ></el-table-column>
        <el-table-column label="小程序显示编号" align="center">
          <template prop="value" slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="显示"
              inactive-value="不显示"
              @change="switchPuweihao($event, scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row._id)"
            ></el-button>
            <el-button
              type="primary"
              icon="el-icon-notebook-2"
              size="mini"
              @click="showDetailDialog(scope.row._id)"
            ></el-button>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="showDeleteDialog(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[50, 25, 10]"
        :page-size="50"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      title="添加店铺/单元信息"
      :visible.sync="addDialogVisible"
      width="50%"
      v-loading="loading"
      @close="addDialogClosed"
      center
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="140px"
      >
        <el-row>
          <el-col span="8">
            <el-form-item label="Mall/OT" prop="MallOT">
              <el-select v-model="addForm.MallOT" placeholder="请选择Mall/OT">
                <el-option label="Mall" value="Mall"></el-option>
                <el-option label="OT" value="OT"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <div v-if="addForm.MallOT == 'Mall'">
          <el-divider>详细信息</el-divider>
          <el-row>
            <el-col span="8">
              <el-form-item label="楼层" prop="Floor">
                <el-select v-model="addForm.Floor" placeholder="请选择店铺楼层">
                  <el-option label="B1" value="B1"></el-option>
                  <el-option label="L1" value="L1"></el-option>
                  <el-option label="L2" value="L2"></el-option>
                  <el-option label="L3" value="L3"></el-option>
                  <el-option label="L4" value="L4"></el-option>
                  <el-option label="L5" value="L5"></el-option>
                  <el-option label="L6" value="L6"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="店铺编号" prop="Shop">
                <el-input v-model="addForm.Shop"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="品牌名称" prop="Brand">
                <el-input v-model="addForm.Brand"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="8">
              <el-form-item label="业态" prop="category">
                <el-select
                  v-model="addForm.category"
                  placeholder="请选择店铺业态"
                >
                  <el-option label="餐饮" value="餐饮"></el-option>
                  <el-option label="饮品" value="饮品"></el-option>
                  <el-option label="零售" value="零售"></el-option>
                  <el-option label="其他" value="其他"></el-option>
                  <el-option label="写字楼" value="写字楼"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="状态" prop="condition">
                <el-select
                  v-model="addForm.condition"
                  placeholder="请选择当前状态"
                >
                  <el-option label="前期对接" value="前期对接"></el-option>
                  <el-option label="审图中" value="审图中"></el-option>
                  <el-option label="施工中" value="施工中"></el-option>
                  <el-option label="已开业" value="已开业"></el-option>
                  <el-option
                    label="已开业（带缺陷）"
                    value="已开业（带缺陷）"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="面积" prop="area">
                <el-input v-model="addForm.area"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="8">
              <el-form-item label="装修PIC" prop="PIC">
                <el-select v-model="addForm.PIC" placeholder="请选择装修组PIC">
                  <el-option label="吴夏" value="吴夏"></el-option>
                  <el-option label="王婧娴" value="王婧娴"></el-option>
                  <el-option label="蒋文洁" value="蒋文洁"></el-option>
                  <el-option label="贾战国" value="贾战国"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="租赁PIC" prop="leasingPIC">
                <el-select
                  v-model="addForm.leasingPIC"
                  placeholder="请选择租赁PIC"
                >
                  <el-option label="Grace" value="Grace"></el-option>
                  <el-option label="Nick" value="Nick"></el-option>
                  <el-option label="Steven" value="Steven"></el-option>
                  <el-option label="Amy" value="Amy"></el-option>
                  <el-option label="Lena" value="Lena"></el-option>
                  <el-option label="Darren" value="Darren"></el-option>
                  <el-option label="Chris" value="Chris"></el-option>
                  <el-option label="Yvette" value="Yvette"></el-option>
                  <el-option label="Chloe" value="Chloe"></el-option>
                  <el-option label="Mary" value="Mary"></el-option>
                  <el-option label="Cindy" value="Cindy"></el-option>
                  <el-option label="Josie" value="Josie"></el-option>
                  <el-option label="Sunnie" value="Sunnie"></el-option>
                  <el-option label="Nicole" value="Nicole"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="施工负责人" prop="shigongfuzeren">
                <el-input v-model="addForm.shigongfuzeren"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="8">
              <el-form-item label="消防图纸电子版" prop="edrawing">
                <el-select
                  v-model="addForm.edrawing"
                  placeholder="请选择是否收到"
                >
                  <el-option label="是" value="是"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="开业检查" prop="openInspection">
                <el-select
                  v-model="addForm.openInspection"
                  placeholder="请选择是否需要"
                >
                  <el-option label="消防支队" value="消防支队"></el-option>
                  <el-option label="住建" value="住建"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="联系电话" prop="mobile">
                <el-input v-model="addForm.mobile"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <div v-if="addForm.MallOT == 'OT'">
          <el-divider>详细信息</el-divider>

          <el-row>
            <el-col span="8">
              <el-form-item label="区" prop="Floor">
                <el-select
                  v-model="addForm.Floor"
                  placeholder="请选择单元所在区"
                >
                  <el-option label="A" value="A"></el-option>
                  <el-option label="B" value="B"></el-option>
                  <el-option label="C" value="C"></el-option>
                  <el-option label="D" value="D"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="单元编号" prop="Shop">
                <el-input v-model="addForm.Shop"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="租户名称" prop="Brand">
                <el-input v-model="addForm.Brand"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="8">
              <el-form-item label="业态" prop="category">
                <el-select
                  v-model="addForm.category"
                  placeholder="请选择店铺业态"
                >
                  <el-option label="保险" value="保险"></el-option>
                  <el-option label="保险、金融" value="保险、金融"></el-option>
                  <el-option label="餐饮" value="餐饮"></el-option>
                  <el-option label="房地产" value="房地产"></el-option>
                  <el-option label="共享办公室" value="共享办公室"></el-option>
                  <el-option label="建筑业" value="建筑业"></el-option>
                  <el-option label="健康产业" value="健康产业"></el-option>
                  <el-option label="教育" value="教育"></el-option>
                  <el-option label="金融" value="金融"></el-option>
                  <el-option label="科技" value="科技"></el-option>
                  <el-option label="零售贸易" value="零售贸易"></el-option>
                  <el-option label="农业" value="农业"></el-option>
                  <el-option label="文化传媒" value="文化传媒"></el-option>
                  <el-option label="律师事务所" value="律师事务所"></el-option>
                  <el-option
                    label="专业代理服务"
                    value="专业代理服务"
                  ></el-option>
                  <el-option label="专业服务" value="专业服务"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="状态" prop="condition">
                <el-select
                  v-model="addForm.condition"
                  placeholder="请选择当前状态"
                >
                  <el-option label="前期对接" value="前期对接"></el-option>
                  <el-option label="审图中" value="审图中"></el-option>
                  <el-option label="施工中" value="施工中"></el-option>
                  <el-option label="已入住" value="已入住"></el-option>
                  <el-option
                    label="已入住（带缺陷）"
                    value="已入住（带缺陷）"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="面积" prop="area">
                <el-input v-model="addForm.area"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="8">
              <el-form-item label="装修PIC" prop="PIC">
                <el-select v-model="addForm.PIC" placeholder="请选择装修组PIC">
                  <el-option label="吴夏" value="吴夏"></el-option>
                  <el-option label="王婧娴" value="王婧娴"></el-option>
                  <el-option label="蒋文洁" value="蒋文洁"></el-option>
                  <el-option label="贾战国" value="贾战国"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="租赁PIC" prop="leasingPIC">
                <el-select
                  v-model="addForm.leasingPIC"
                  placeholder="请选择租赁PIC"
                >
                  <el-option label="赵利萍" value="赵利萍"></el-option>
                  <el-option label="王明" value="王明"></el-option>
                  <el-option label="毛家涵" value="毛家涵"></el-option>
                  <el-option label="李准" value="李准"></el-option>
                  <el-option label="郭乐" value="郭乐"></el-option>
                  <el-option label="曹芳艳" value="曹芳艳"></el-option>
                  <el-option label="Darren" value="Darren"></el-option>
                  <el-option label="温玮昕" value="温玮昕"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="施工负责人" prop="shigongfuzeren">
                <el-input v-model="addForm.shigongfuzeren"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="8">
              <el-form-item label="消防图纸电子版" prop="edrawing">
                <el-select
                  v-model="addForm.edrawing"
                  placeholder="请选择是否收到"
                >
                  <el-option label="是" value="是"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="开业检查" prop="openInspection">
                <el-select
                  v-model="addForm.openInspection"
                  placeholder="请选择是否需要"
                >
                  <el-option label="消防支队" value="消防支队"></el-option>
                  <el-option label="住建" value="住建"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="联系电话" prop="mobile">
                <el-input v-model="addForm.mobile"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="8">
              <el-form-item label="挡烟垂壁" prop="dangyan">
                <el-select
                  v-model="addForm.dangyan"
                  placeholder="请选择是否涉及挡烟垂壁"
                >
                  <el-option label="是" value="是"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="隔墙是否到顶" prop="geqiangdaoding">
                <el-select
                  v-model="addForm.geqiangdaoding"
                  placeholder="请选择隔墙是否到顶"
                >
                  <el-option label="是" value="是"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <el-divider>图纸审核</el-divider>

        <el-row>
          <el-col span="9">
            <el-form-item label="设计方案" prop="design">
              <el-select
                v-model="addForm.design"
                placeholder="请选择设计方案状态"
              >
                <el-option label="1" value="1"></el-option>
                <el-option label="2" value="2"></el-option>
                <el-option label="3" value="3"></el-option>
                <el-option label="4" value="4"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="6" value="6"></el-option>
                <el-option label="通过" value="通过"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col span="8">
            <el-form-item label="施工图审核次数" prop="construct">
              <el-input-number
                v-model="addForm.construct"
                :min="0"
                :max="10"
                label="请选择施工图审核次数"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col span="8">
            <el-form-item label="装修许可证" prop="fittingAuth">
              <el-date-picker
                v-model="addForm.fittingAuth"
                align="right"
                type="date"
                placeholder="装修许可证签批日期"
                value-format="yyyy-M-d"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-form-item label="可施工项目" prop="allowed">
            <el-checkbox-group v-model="addForm.allowed">
              <el-checkbox label="全专业"></el-checkbox>
              <el-checkbox label="装修"></el-checkbox>
              <el-checkbox label="强电"></el-checkbox>
              <el-checkbox label="弱电"></el-checkbox>
              <el-checkbox label="给排水"></el-checkbox>
              <el-checkbox label="空调"></el-checkbox>
              <el-checkbox label="暂无"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-row>

        <el-divider>验收信息</el-divider>

        <el-row>
          <el-col span="8">
            <el-form-item label="隔墙验收" prop="wallInspect">
              <el-select
                v-model="addForm.wallInspect"
                placeholder="请选择验收情况"
              >
                <el-option label="未通过" value="未通过"></el-option>
                <el-option label="通过" value="通过"></el-option>
                <el-option label="不涉及" value="不涉及"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col span="8">
            <el-form-item label="吊顶验收" prop="ceilInspect">
              <el-select
                v-model="addForm.ceilInspect"
                placeholder="请选择验收情况"
              >
                <el-option label="未通过" value="未通过"></el-option>
                <el-option label="通过" value="通过"></el-option>
                <el-option label="不涉及" value="不涉及"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col span="8">
            <el-form-item label="防水验收" prop="waterInspect">
              <el-select
                v-model="addForm.waterInspect"
                placeholder="请选择验收情况"
              >
                <el-option label="未通过" value="未通过"></el-option>
                <el-option label="通过" value="通过"></el-option>
                <el-option label="不涉及" value="不涉及"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col span="8">
            <el-form-item label="给水管打压验收" prop="pipeInspect">
              <el-select
                v-model="addForm.pipeInspect"
                placeholder="请选择验收情况"
              >
                <el-option label="未通过" value="未通过"></el-option>
                <el-option label="通过" value="通过"></el-option>
                <el-option label="不涉及" value="不涉及"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col span="8">
            <el-form-item label="空调水管清洗打压" prop="HVACInspect">
              <el-select
                v-model="addForm.HVACInspect"
                placeholder="请选择验收情况"
              >
                <el-option label="未通过" value="未通过"></el-option>
                <el-option label="通过" value="通过"></el-option>
                <el-option label="不涉及" value="不涉及"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col span="8">
            <el-form-item label="竣工验收" prop="finalInspect">
              <el-select
                v-model="addForm.finalInspect"
                placeholder="请选择验收情况"
              >
                <el-option label="未通过" value="未通过"></el-option>
                <el-option label="通过" value="通过"></el-option>
                <el-option label="不涉及" value="不涉及"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-divider>其他信息</el-divider>
        <el-row>
          <el-form-item label="备注" prop="info">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入备注内容"
              v-model="addForm.info"
            >
            </el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addUser" size="medium"
          >添 加</el-button
        >
        <el-button @click="addDialogVisible = false" size="medium"
          >取 消</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="删除店铺信息"
      :visible.sync="deleteDialogVisible"
      width="20%"
      v-loading="loading"
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="danger" @click="deleteList">确认删除</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改店铺信息"
      :visible.sync="editDialogVisible"
      width="50%"
      v-loading="loading"
      @close="editDialogClosed"
      center
    >
      <el-form
        :model="addForm1"
        :rules="addFormRules"
        ref="addFormRef1"
        label-width="140px"
      >
        <el-row>
          <el-col span="8">
            <el-form-item label="Mall/OT" prop="MallOT">
              <el-select v-model="addForm1.MallOT" placeholder="请选择Mall/OT">
                <el-option label="Mall" value="Mall"></el-option>
                <el-option label="OT" value="OT"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <div v-if="addForm1.MallOT == 'Mall'">
          <el-divider>详细信息</el-divider>
          <el-row>
            <el-col span="8">
              <el-form-item label="楼层" prop="Floor">
                <el-select
                  v-model="addForm1.Floor"
                  placeholder="请选择店铺楼层"
                >
                  <el-option label="B1" value="B1"></el-option>
                  <el-option label="L1" value="L1"></el-option>
                  <el-option label="L2" value="L2"></el-option>
                  <el-option label="L3" value="L3"></el-option>
                  <el-option label="L4" value="L4"></el-option>
                  <el-option label="L5" value="L5"></el-option>
                  <el-option label="L6" value="L6"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="店铺编号" prop="Shop">
                <el-input v-model="addForm1.Shop"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="品牌名称" prop="Brand">
                <el-input v-model="addForm1.Brand"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="8">
              <el-form-item label="业态" prop="category">
                <el-select
                  v-model="addForm1.category"
                  placeholder="请选择店铺业态"
                >
                  <el-option label="餐饮" value="餐饮"></el-option>
                  <el-option label="饮品" value="饮品"></el-option>
                  <el-option label="零售" value="零售"></el-option>
                  <el-option label="其他" value="其他"></el-option>
                  <el-option label="写字楼" value="写字楼"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="状态" prop="condition">
                <el-select
                  v-model="addForm1.condition"
                  placeholder="请选择当前状态"
                >
                  <el-option label="前期对接" value="前期对接"></el-option>
                  <el-option label="审图中" value="审图中"></el-option>
                  <el-option label="施工中" value="施工中"></el-option>
                  <el-option label="已开业" value="已开业"></el-option>
                  <el-option
                    label="已开业（带缺陷）"
                    value="已开业（带缺陷）"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="面积" prop="area">
                <el-input v-model="addForm1.area"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="8">
              <el-form-item label="装修PIC" prop="PIC">
                <el-select v-model="addForm1.PIC" placeholder="请选择装修组PIC">
                  <el-option label="吴夏" value="吴夏"></el-option>
                  <el-option label="王婧娴" value="王婧娴"></el-option>
                  <el-option label="蒋文洁" value="蒋文洁"></el-option>
                  <el-option label="贾战国" value="贾战国"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="租赁PIC" prop="leasingPIC">
                <el-select
                  v-model="addForm1.leasingPIC"
                  placeholder="请选择租赁PIC"
                >
                  <el-option label="Grace" value="Grace"></el-option>
                  <el-option label="Nick" value="Nick"></el-option>
                  <el-option label="Steven" value="Steven"></el-option>
                  <el-option label="Amy" value="Amy"></el-option>
                  <el-option label="Lena" value="Lena"></el-option>
                  <el-option label="Darren" value="Darren"></el-option>
                  <el-option label="Chris" value="Chris"></el-option>
                  <el-option label="Yvette" value="Yvette"></el-option>
                  <el-option label="Chloe" value="Chloe"></el-option>
                  <el-option label="Mary" value="Mary"></el-option>
                  <el-option label="Cindy" value="Cindy"></el-option>
                  <el-option label="Josie" value="Josie"></el-option>
                  <el-option label="Sunnie" value="Sunnie"></el-option>
                  <el-option label="Nicole" value="Nicole"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="施工负责人" prop="shigongfuzeren">
                <el-input v-model="addForm1.shigongfuzeren"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="8">
              <el-form-item label="消防图纸电子版" prop="edrawing">
                <el-select
                  v-model="addForm1.edrawing"
                  placeholder="请选择是否收到"
                >
                  <el-option label="是" value="是"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="开业检查" prop="openInspection">
                <el-select
                  v-model="addForm1.openInspection"
                  placeholder="请选择是否需要"
                >
                  <el-option label="消防支队" value="消防支队"></el-option>
                  <el-option label="住建" value="住建"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="联系电话" prop="mobile">
                <el-input v-model="addForm1.mobile"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <div v-if="addForm1.MallOT == 'OT'">
          <el-divider>详细信息</el-divider>

          <el-row>
            <el-col span="8">
              <el-form-item label="区" prop="Floor">
                <el-select
                  v-model="addForm1.Floor"
                  placeholder="请选择单元所在区"
                >
                  <el-option label="A" value="A"></el-option>
                  <el-option label="B" value="B"></el-option>
                  <el-option label="C" value="C"></el-option>
                  <el-option label="D" value="D"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="单元编号" prop="Shop">
                <el-input v-model="addForm1.Shop"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="租户名称" prop="Brand">
                <el-input v-model="addForm1.Brand"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="8">
              <el-form-item label="业态" prop="category">
                <el-select
                  v-model="addForm1.category"
                  placeholder="请选择店铺业态"
                >
                  <el-option label="保险" value="保险"></el-option>
                  <el-option label="保险、金融" value="保险、金融"></el-option>
                  <el-option label="餐饮" value="餐饮"></el-option>
                  <el-option label="房地产" value="房地产"></el-option>
                  <el-option label="共享办公室" value="共享办公室"></el-option>
                  <el-option label="建筑业" value="建筑业"></el-option>
                  <el-option label="健康产业" value="健康产业"></el-option>
                  <el-option label="教育" value="教育"></el-option>
                  <el-option label="金融" value="金融"></el-option>
                  <el-option label="科技" value="科技"></el-option>
                  <el-option label="零售贸易" value="零售贸易"></el-option>
                  <el-option label="农业" value="农业"></el-option>
                  <el-option label="文化传媒" value="文化传媒"></el-option>
                  <el-option label="律师事务所" value="律师事务所"></el-option>
                  <el-option
                    label="专业代理服务"
                    value="专业代理服务"
                  ></el-option>
                  <el-option label="专业服务" value="专业服务"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="状态" prop="condition">
                <el-select
                  v-model="addForm1.condition"
                  placeholder="请选择当前状态"
                >
                  <el-option label="前期对接" value="前期对接"></el-option>
                  <el-option label="审图中" value="审图中"></el-option>
                  <el-option label="施工中" value="施工中"></el-option>
                  <el-option label="已入住" value="已入住"></el-option>
                  <el-option
                    label="已入住（带缺陷）"
                    value="已入住（带缺陷）"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="面积" prop="area">
                <el-input v-model="addForm1.area"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="8">
              <el-form-item label="装修PIC" prop="PIC">
                <el-select v-model="addForm1.PIC" placeholder="请选择装修组PIC">
                  <el-option label="吴夏" value="吴夏"></el-option>
                  <el-option label="王婧娴" value="王婧娴"></el-option>
                  <el-option label="蒋文洁" value="蒋文洁"></el-option>
                  <el-option label="贾战国" value="贾战国"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="租赁PIC" prop="leasingPIC">
                <el-select
                  v-model="addForm1.leasingPIC"
                  placeholder="请选择租赁PIC"
                >
                  <el-option label="赵利萍" value="赵利萍"></el-option>
                  <el-option label="王明" value="王明"></el-option>
                  <el-option label="毛家涵" value="毛家涵"></el-option>
                  <el-option label="李准" value="李准"></el-option>
                  <el-option label="郭乐" value="郭乐"></el-option>
                  <el-option label="曹芳艳" value="曹芳艳"></el-option>
                  <el-option label="Darren" value="Darren"></el-option>
                  <el-option label="温玮昕" value="温玮昕"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="施工负责人" prop="shigongfuzeren">
                <el-input v-model="addForm1.shigongfuzeren"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="8">
              <el-form-item label="消防图纸电子版" prop="edrawing">
                <el-select
                  v-model="addForm1.edrawing"
                  placeholder="请选择是否收到"
                >
                  <el-option label="是" value="是"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="开业检查" prop="openInspection">
                <el-select
                  v-model="addForm1.openInspection"
                  placeholder="请选择是否需要"
                >
                  <el-option label="消防支队" value="消防支队"></el-option>
                  <el-option label="住建" value="住建"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="联系电话" prop="mobile">
                <el-input v-model="addForm1.mobile"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="8">
              <el-form-item label="挡烟垂壁" prop="dangyan">
                <el-select
                  v-model="addForm1.dangyan"
                  placeholder="请选择是否涉及挡烟垂壁"
                >
                  <el-option label="是" value="是"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item label="隔墙是否到顶" prop="geqiangdaoding">
                <el-select
                  v-model="addForm1.geqiangdaoding"
                  placeholder="请选择隔墙是否到顶"
                >
                  <el-option label="是" value="是"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <el-divider>图纸审核</el-divider>

        <el-row>
          <el-col span="9">
            <el-form-item label="设计方案" prop="design">
              <el-select
                v-model="addForm1.design"
                placeholder="请选择设计方案状态"
              >
                <el-option label="1" value="1"></el-option>
                <el-option label="2" value="2"></el-option>
                <el-option label="3" value="3"></el-option>
                <el-option label="4" value="4"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="6" value="6"></el-option>
                <el-option label="通过" value="通过"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col span="8">
            <el-form-item label="施工图审核次数" prop="construct">
              <el-input-number
                v-model="addForm1.construct"
                :min="0"
                :max="10"
                label="请选择施工图审核次数"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col span="8">
            <el-form-item label="装修许可证" prop="fittingAuth">
              <el-date-picker
                v-model="addForm1.fittingAuth"
                align="right"
                type="date"
                placeholder="装修许可证签批日期"
                value-format="yyyy-M-d"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-form-item label="可施工项目" prop="allowed">
            <el-checkbox-group v-model="addForm1.allowed">
              <el-checkbox label="全专业"></el-checkbox>
              <el-checkbox label="装修"></el-checkbox>
              <el-checkbox label="强电"></el-checkbox>
              <el-checkbox label="弱电"></el-checkbox>
              <el-checkbox label="给排水"></el-checkbox>
              <el-checkbox label="空调"></el-checkbox>
              <el-checkbox label="暂无"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-row>

        <el-divider>验收信息</el-divider>

        <el-row>
          <el-col span="8">
            <el-form-item label="隔墙验收" prop="wallInspect">
              <el-select
                v-model="addForm1.wallInspect"
                placeholder="请选择验收情况"
              >
                <el-option label="未通过" value="未通过"></el-option>
                <el-option label="通过" value="通过"></el-option>
                <el-option label="不涉及" value="不涉及"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col span="8">
            <el-form-item label="吊顶验收" prop="ceilInspect">
              <el-select
                v-model="addForm1.ceilInspect"
                placeholder="请选择验收情况"
              >
                <el-option label="未通过" value="未通过"></el-option>
                <el-option label="通过" value="通过"></el-option>
                <el-option label="不涉及" value="不涉及"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col span="8">
            <el-form-item label="防水验收" prop="waterInspect">
              <el-select
                v-model="addForm1.waterInspect"
                placeholder="请选择验收情况"
              >
                <el-option label="未通过" value="未通过"></el-option>
                <el-option label="通过" value="通过"></el-option>
                <el-option label="不涉及" value="不涉及"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col span="8">
            <el-form-item label="给水管打压验收" prop="pipeInspect">
              <el-select
                v-model="addForm1.pipeInspect"
                placeholder="请选择验收情况"
              >
                <el-option label="未通过" value="未通过"></el-option>
                <el-option label="通过" value="通过"></el-option>
                <el-option label="不涉及" value="不涉及"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col span="8">
            <el-form-item label="空调水管清洗打压" prop="HVACInspect">
              <el-select
                v-model="addForm1.HVACInspect"
                placeholder="请选择验收情况"
              >
                <el-option label="未通过" value="未通过"></el-option>
                <el-option label="通过" value="通过"></el-option>
                <el-option label="不涉及" value="不涉及"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col span="8">
            <el-form-item label="竣工验收" prop="finalInspect">
              <el-select
                v-model="addForm1.finalInspect"
                placeholder="请选择验收情况"
              >
                <el-option label="未通过" value="未通过"></el-option>
                <el-option label="通过" value="通过"></el-option>
                <el-option label="不涉及" value="不涉及"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-divider>其他信息</el-divider>
        <el-row>
          <el-form-item label="备注" prop="info">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入备注内容"
              v-model="addForm1.info"
            >
            </el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="edit">修 改</el-button>
      </span>
    </el-dialog>

    <el-dialog title="详情" :visible.sync="detailDialog" width="50%">
      <section id="printForm" ref="form">
        <!-- <h1 class="h1-page">昆明恒隆广场（商场）商铺（工程）整改通知单</h1> -->
        <table class="table-page">
          <tbody>
            <tr>
              <td colspan="4" class="title1">
                昆明恒隆广场（商场）{{ this.addForm1.Shop }}
                商铺（工程）检查清单
              </td>
            </tr>
            <tr>
              <td>商铺编号</td>
              <td>{{ this.addForm1.Shop }}</td>
              <td>商铺名称</td>
              <td>{{ this.addForm1.Brand }}</td>
            </tr>
            <tr>
              <td>查询日期</td>
              <td>{{ this.date }}</td>
              <td>查询时间</td>
              <td>{{ this.Time }}</td>
            </tr>
            <tr>
              <td>消防图纸电子版</td>
              <td>{{ this.addForm1.edrawing }}</td>
              <td>开业检查</td>
              <td>{{ this.addForm1.openInspection }}</td>
            </tr>
            <tr>
              <td colspan="1">可施工项目</td>
              <td colspan="3">{{ this.addForm1.allowed }}</td>
            </tr>
            <tr>
              <td>隔墙隐蔽验收</td>
              <td>{{ this.addForm1.wallInspect }}</td>
              <td>吊顶隐蔽验收</td>
              <td>{{ this.addForm1.ceilInspect }}</td>
            </tr>
            <tr>
              <td colspan="2">未消项整改数量</td>
              <td class="align-center" colspan="2">
                <div class="text-underline">{{ this.dataList.length }} 条</div>
              </td>
            </tr>
            <tr>
              <td class="align-left" colspan="4">
                <el-table :data="dataList" border stripe>
                  <el-table-column type="index" label="#"></el-table-column>
                  <el-table-column
                    prop="zhenggaixiangmu"
                    label="整改项目"
                  ></el-table-column>
                  <el-table-column
                    prop="date"
                    label="上传时间"
                  ></el-table-column>
                  <el-table-column prop="fileIDs" label="照片">
                    <template slot-scope="scope">
                      <span
                        v-for="(item, index) in scope.row.fileIDs"
                        :key="index"
                      >
                        <el-popover
                          placement="left"
                          trigger="click"
                          width="300"
                        >
                          <img :src="item" width="100%" />
                          <img
                            slot="reference"
                            :src="item"
                            :alt="item"
                            style="
                              max-height: 70px;
                              max-width: 70px;
                              padding: 5px;
                            "
                          />
                        </el-popover>
                      </span>
                    </template>
                    ></el-table-column
                  >
                </el-table>
              </td>
            </tr>
            <tr>
              <td colspan="1" rowspan="2">备注：</td>
              <td colspan="3" rowspan="2"></td>
            </tr>
          </tbody>
        </table>
      </section>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detailDialog = false">取 消</el-button>
        <el-button type="primary" @click="print">打 印</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import exportExcel from "js-table2excel";
export default {
  data() {
    return {
      filterOptions: ["Mall", "OT"],
      filterOptions_1: [
        "前期对接",
        "审图中",
        "施工中",
        "已开业",
        "已开业（带缺陷）",
      ],
      finalList: [],
      tempFileURL: [],
      Time: "",
      date: "",
      dataList1: [],
      dataList: [],
      checkboxGroup1: ["Mall", "OT"],
      checkboxGroup2: ["审图中","施工中"],
      userList: [],
      total: 0,
      pageSize: 50,
      pageNum: 1,
      searchFeild: "",
      addDialogVisible: false,
      detailDialog: false,
      addForm: {
        MallOT: "",
        Floor: "",
        Shop: "",
        Brand: "",
        category: "",
        condition: "",
        area: "",
        PIC: "",
        fittingAuth: "",
        wallInspect: "未验收",
        ceilInspect: "未验收",
        finalInspect: "未验收",
        allowed: [],
        design: "",
        construct: "",
        edrawing: "",
        openInspection: "",
        info: "",
        leasingPIC: "",
        shigongfuzeren: "",
        mobile: "",
        dangyan: "",
        geqiangdaoding: "",
        waterInspect: "不涉及",
        pipeInspect: "不涉及",
        HVACInspect: "不涉及",
      },
      addForm1: {
        MallOT: "",
        Floor: "",
        Shop: "",
        Brand: "",
        category: "",
        condition: "",
        area: "",
        PIC: "",
        fittingAuth: "",
        wallInspect: "",
        ceilInspect: "",
        finalInspect: "",
        allowed: [],
        design: "",
        construct: "",
        edrawing: "",
        openInspection: "",
        info: "",
        leasingPIC: "",
        shigongfuzeren: "",
        mobile: "",
        dangyan: "",
        geqiangdaoding: "",
        waterInspect: "",
        pipeInspect: "",
        HVACInspect: "",
      },
      addFormRules: {
        MallOT: [
          { required: true, message: "请选择Mall或OT", trigger: "blur" },
        ],
        Shop: [
          { required: true, message: "请输入店铺/单元编号", trigger: "blur" },
        ],
        Brand: [
          { required: true, message: "请输入品牌/租户名称", trigger: "blur" },
        ],
        area: [
          { required: true, message: "请输入店铺租赁面积", trigger: "blur" },
        ],
        category: [
          { required: true, message: "请选择店铺业态", trigger: "blur" },
        ],
        allowed: [
          { required: true, message: "请选择可施工项目", trigger: "blur" },
        ],
        Floor: [
          { required: true, message: "请选择店铺所在楼层", trigger: "blur" },
        ],
        condition: [
          { required: true, message: "请选择店铺状态", trigger: "blur" },
        ],
        PIC: [{ required: true, message: "请选择装修组PIC", trigger: "blur" }],
        leasingPIC: [
          { required: true, message: "请选择租赁PIC", trigger: "blur" },
        ],
      },
      loading: false,
      value1: "",
      value: "",
      checkList: [],
      puweihaoList: [],
      resPuweihaoList: [],
      deleteDialogVisible: false,
      puweihao: [],
      resPuweihao: [],
      editDialogVisible: false,
      value: true,
    };
  },
  async created() {
    const loginState = this.$cloudbase.auth().hasLoginState();
    if (loginState) {
      // 登录态有效
      console.log("登录状态有效");
      this.getUserList();
    } else {
      console.log("登录状态无效");
      this.$router.push("/login");
    }
  },
  methods: {
    async filterChange(event) {
      const db = this.$cloudbase.database();
      const _ = db.command;
      if (this.checkboxGroup1.length == 1) {
        if (this.checkboxGroup2.length == 0) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: "",
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: "",
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 1) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: this.checkboxGroup2[0],
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: this.checkboxGroup2[0],
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 2) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 3) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 4) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 5) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
      }
      if (this.checkboxGroup1.length == 2) {
        if (this.checkboxGroup2.length == 0) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: "",
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: "",
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 1) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: this.checkboxGroup2[0],
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: this.checkboxGroup2[0],
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 2) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 3) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 4) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 5) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
      }
      if (this.checkboxGroup1.length == 0) {
        if (this.checkboxGroup2.length == 0) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: "",
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: "",
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 1) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: this.checkboxGroup2[0],
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: this.checkboxGroup2[0],
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 2) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 3) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 4) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 5) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
      }
    },

    async filterChange_1(event) {
      console.log(this.checkboxGroup2, this.checkboxGroup1)
      const db = this.$cloudbase.database();
      const _ = db.command;
      if (this.checkboxGroup1.length == 1) {
        if (this.checkboxGroup2.length == 0) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: "",
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: "",
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 1) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: this.checkboxGroup2[0],
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: this.checkboxGroup2[0],
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 2) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 3) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 4) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 5) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
      }
      if (this.checkboxGroup1.length == 2) {
        if (this.checkboxGroup2.length == 0) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: "",
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: "",
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 1) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: this.checkboxGroup2[0],
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: this.checkboxGroup2[0],
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 2) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 3) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表222：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 4) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 5) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
      }
      if (this.checkboxGroup1.length == 0) {
        if (this.checkboxGroup2.length == 0) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: "",
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: "",
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 1) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: this.checkboxGroup2[0],
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: this.checkboxGroup2[0],
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 2) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 3) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 4) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 5) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
      }
    },

    handleSizeChange(newSize) {
      console.log("每页显示数量", newSize);
      this.pageSize = newSize;
      this.getUserList();
    },

    handleCurrentChange(newPage) {
      console.log("页码", newPage);
      this.pageNum = newPage;
      this.getUserList();
    },

    async getUserList() {
      const db = this.$cloudbase.database();
      const _ = db.command;
      if (this.checkboxGroup1.length == 1) {
        if (this.checkboxGroup2.length == 0) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: "",
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: "",
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 1) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: this.checkboxGroup2[0],
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: this.checkboxGroup2[0],
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 2) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 3) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 4) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 5) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
      }
      if (this.checkboxGroup1.length == 2) {
        if (this.checkboxGroup2.length == 0) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: "",
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: "",
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 1) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: this.checkboxGroup2[0],
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: this.checkboxGroup2[0],
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 2) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 3) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 4) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 5) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: _.or(
                _.eq(this.checkboxGroup1[0]),
                _.eq(this.checkboxGroup1[1])),
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
      }
      if (this.checkboxGroup1.length == 0) {
        if (this.checkboxGroup2.length == 0) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: "",
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: "",
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 1) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: this.checkboxGroup2[0],
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: this.checkboxGroup2[0],
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 2) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 3) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 4) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
        if (this.checkboxGroup2.length == 5) {
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .limit(this.pageSize)
            .skip(this.pageSize * (this.pageNum - 1))
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .orderBy("Shop", "asc")
            .get()
            .then((res) => {
              console.log("用户列表111：", res.data);
              this.userList = res.data;
            });
          await this.$cloudbase
            .database()
            .collection("shopInfo")
            .where({
              MallOT: this.checkboxGroup1[0],
              condition: _.or(
                _.eq(this.checkboxGroup2[0]),
                _.eq(this.checkboxGroup2[1]),
                _.eq(this.checkboxGroup2[2]),
                _.eq(this.checkboxGroup2[3]),
                _.eq(this.checkboxGroup2[4])
              ),
            })
            .count()
            .then((res) => {
              console.log("用户列表长度111：", res.total);
              this.total = res.total;
            });
        }
      }
    },

    search() {
      console.log("-----------", this.searchFeild);
      const db = this.$cloudbase.database();
      const _ = db.command;
      this.$cloudbase
        .database()
        .collection("shopInfo")
        .limit(this.pageSize)
        .skip(this.pageSize * (this.pageNum - 1))
        .where(
          _.or([
            {
              Shop: db.RegExp({
                regexp: this.searchFeild,
                options: "i",
              }),
            },
            {
              Brand: db.RegExp({
                regexp: this.searchFeild,
                options: "i",
              }),
            },
          ])
        )
        .get()
        .then((res) => {
          console.log("用户列表：", res.data);
          this.userList = res.data;
          this.total = res.data.length;
        });
    },

    addUser() {
      this.$refs.addFormRef.validate((valid) => {
        if (!valid) return;
        this.loading = true;
        if (
          (this.addForm.category == "餐饮" && this.addForm.area < 200) ||
          (this.addForm.category == "饮品" && this.addForm.area < 100)
        ) {
          this.$cloudbase
            .database()
            .collection("shopInfo")
            .add({
              MallOT: this.addForm.MallOT,
              Floor: this.addForm.Floor,
              Shop: this.addForm.Shop,
              Brand: this.addForm.Brand,
              category: this.addForm.category,
              condition: this.addForm.condition,
              area: this.addForm.area,
              PIC: this.addForm.PIC,
              fittingAuth: this.addForm.fittingAuth,
              wallInspect: this.addForm.wallInspect,
              ceilInspect: this.addForm.ceilInspect,
              finalInspect: this.addForm.finalInspect,
              allowed: this.addForm.allowed.toString(),
              design: this.addForm.design,
              construct: this.addForm.construct,
              edrawing: this.addForm.edrawing,
              openInspection: "消防支队",
              info: this.addForm.info,
              leasingPIC: this.addForm.leasingPIC,
              shigongfuzeren: this.addForm.shigongfuzeren,
              mobile: this.addForm.mobile,
              status: "不显示",
              puweihao: this.addForm.Shop + "-" + this.addForm.Brand,
              dangyan: this.addForm.dangyan,
              geqiangdaoding: this.addForm.geqiangdaoding,
              waterInspect: this.addForm.waterInspect,
              pipeInspect: this.addForm.pipeInspect,
              HVACInspect: this.addForm.HVACInspect,
            })
            .then((res) => {
              console.log("更新结果", res);
              this.$message.success("店铺信息添加成功");
              this.addDialogVisible = false;
              this.getUserList();
              this.loading = false;
            })
            .catch((err) => {
              this.$message.error("添加失败");
              console.log(err);
            });
        } else if (
          (this.addForm.category == "餐饮" && this.addForm.area >= 200) ||
          (this.addForm.category == "饮品" && this.addForm.area >= 100) ||
          (this.addForm.category == "零售" && this.addForm.area >= 1000)
        ) {
          this.$cloudbase
            .database()
            .collection("shopInfo")
            .add({
              MallOT: this.addForm.MallOT,
              Floor: this.addForm.Floor,
              Shop: this.addForm.Shop,
              Brand: this.addForm.Brand,
              category: this.addForm.category,
              condition: this.addForm.condition,
              area: this.addForm.area,
              PIC: this.addForm.PIC,
              fittingAuth: this.addForm.fittingAuth,
              wallInspect: this.addForm.wallInspect,
              ceilInspect: this.addForm.ceilInspect,
              finalInspect: this.addForm.finalInspect,
              allowed: this.addForm.allowed.toString(),
              design: this.addForm.design,
              construct: this.addForm.construct,
              edrawing: this.addForm.edrawing,
              openInspection: "消防支队",
              info: this.addForm.info,
              leasingPIC: this.addForm.leasingPIC,
              shigongfuzeren: this.addForm.shigongfuzeren,
              mobile: this.addForm.mobile,
              status: "不显示",
              puweihao: this.addForm.Shop + "-" + this.addForm.Brand,
              dangyan: this.addForm.dangyan,
              geqiangdaoding: this.addForm.geqiangdaoding,
              waterInspect: this.addForm.waterInspect,
              pipeInspect: this.addForm.pipeInspect,
              HVACInspect: this.addForm.HVACInspect,
            })
            .then((res) => {
              console.log("更新结果", res);
              this.$message.success("店铺信息添加成功");
              this.addDialogVisible = false;
              this.getUserList();
              this.loading = false;
            })
            .catch((err) => {
              this.$message.error("添加失败");
              console.log(err);
            });
        } else {
          this.$cloudbase
            .database()
            .collection("shopInfo")
            .add({
              MallOT: this.addForm.MallOT,
              Floor: this.addForm.Floor,
              Shop: this.addForm.Shop,
              Brand: this.addForm.Brand,
              category: this.addForm.category,
              condition: this.addForm.condition,
              area: this.addForm.area,
              PIC: this.addForm.PIC,
              fittingAuth: this.addForm.fittingAuth,
              wallInspect: this.addForm.wallInspect,
              ceilInspect: this.addForm.ceilInspect,
              finalInspect: this.addForm.finalInspect,
              allowed: this.addForm.allowed.toString(),
              design: this.addForm.design,
              construct: this.addForm.construct,
              edrawing: this.addForm.edrawing,
              openInspection: "否",
              info: this.addForm.info,
              leasingPIC: this.addForm.leasingPIC,
              shigongfuzeren: this.addForm.shigongfuzeren,
              mobile: this.addForm.mobile,
              status: "不显示",
              puweihao: this.addForm.Shop + "-" + this.addForm.Brand,
              dangyan: this.addForm.dangyan,
              geqiangdaoding: this.addForm.geqiangdaoding,
              waterInspect: this.addForm.waterInspect,
              pipeInspect: this.addForm.pipeInspect,
              HVACInspect: this.addForm.HVACInspect,
            })
            .then((res) => {
              console.log("更新结果", res);
              this.$message.success("店铺信息添加成功");
              this.addDialogVisible = false;
              this.getUserList();
              this.loading = false;
            })
            .catch((err) => {
              this.$message.error("添加失败");
              console.log(err);
            });
        }
      });
    },

    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },

    showDeleteDialog(res) {
      this.deleteDialogVisible = true;
      this.id = res._id;
      this.res = res;
    },

    async deleteList() {
      this.loading = true;
      await this.$app
        .callFunction({
          name: "deleteShopIfon",
          data: {
            dataID: this.id,
          },
        })
        .then((res) => {
          this.loading = false;
          this.$message.success("删除成功");
          this.deleteDialogVisible = false;
        })
        .catch((err) => {
          this.$message.error("删除失败");
          console.log(err);
        });
    },

    editDialogClosed() {
      this.$refs.addFormRef1.resetFields();
    },

    async showEditDialog(id) {
      this.editDialogVisible = true;
      this.editeID = id;
      await this.$cloudbase
        .database()
        .collection("shopInfo")
        .where({
          _id: id,
        })
        .get()
        .then((res) => {
          console.log("用户信息", res.data[0]);
          this.addForm1.MallOT = res.data[0].MallOT;
          this.addForm1.Floor = res.data[0].Floor;
          this.addForm1.Shop = res.data[0].Shop;
          this.addForm1.Brand = res.data[0].Brand;
          this.addForm1.category = res.data[0].category;
          this.addForm1.condition = res.data[0].condition;
          this.addForm1.area = res.data[0].area;
          this.addForm1.PIC = res.data[0].PIC;
          this.addForm1.fittingAuth = res.data[0].fittingAuth;
          this.addForm1.wallInspect = res.data[0].wallInspect;
          this.addForm1.ceilInspect = res.data[0].ceilInspect;
          this.addForm1.finalInspect = res.data[0].finalInspect;
          this.addForm1.allowed = res.data[0].allowed.split(",");
          this.addForm1.design = res.data[0].design;
          this.addForm1.construct = res.data[0].construct;
          this.addForm1.edrawing = res.data[0].edrawing;
          this.addForm1.openInspection = res.data[0].openInspection;
          this.addForm1.info = res.data[0].info;
          this.addForm1.leasingPIC = res.data[0].leasingPIC;
          this.addForm1.shigongfuzeren = res.data[0].shigongfuzeren;
          this.addForm1.mobile = res.data[0].mobile;
          this.addForm1.dangyan = this.addForm.dangyan;
          this.addForm1.geqiangdaoding = res.data[0].geqiangdaoding;
          this.addForm1.waterInspect = res.data[0].waterInspect;
          this.addForm1.pipeInspect = res.data[0].pipeInspect;
          this.addForm1.HVACInspect = res.data[0].HVACInspect;
        });
    },

    async showDetailDialog(id) {
      let time = new Date();
      this.detailDialog = true;
      this.editeID = id;
      this.date = this.GetDateStr(0);
      this.Time = time.toLocaleTimeString();
      await this.$cloudbase
        .database()
        .collection("shopInfo")
        .where({
          _id: id,
        })
        .get()
        .then((res) => {
          console.log("用户信息", res.data[0]);
          this.addForm1.MallOT = res.data[0].MallOT;
          this.addForm1.Floor = res.data[0].Floor;
          this.addForm1.Shop = res.data[0].Shop;
          this.addForm1.Brand = res.data[0].Brand;
          this.addForm1.category = res.data[0].category;
          this.addForm1.condition = res.data[0].condition;
          this.addForm1.area = res.data[0].area;
          this.addForm1.PIC = res.data[0].PIC;
          this.addForm1.fittingAuth = res.data[0].fittingAuth;
          this.addForm1.wallInspect = res.data[0].wallInspect;
          this.addForm1.ceilInspect = res.data[0].ceilInspect;
          this.addForm1.finalInspect = res.data[0].finalInspect;
          this.addForm1.allowed = res.data[0].allowed.split(",");
          this.addForm1.design = res.data[0].design;
          this.addForm1.construct = res.data[0].construct;
          this.addForm1.edrawing = res.data[0].edrawing;
          this.addForm1.openInspection = res.data[0].openInspection;
          this.addForm1.info = res.data[0].info;
          this.addForm1.leasingPIC = res.data[0].leasingPIC;
          this.addForm1.shigongfuzeren = res.data[0].shigongfuzeren;
          this.addForm1.mobile = res.data[0].mobile;
          this.addForm1.dangyan = this.addForm.dangyan;
          this.addForm1.geqiangdaoding = res.data[0].geqiangdaoding;
          this.addForm1.waterInspect = res.data[0].waterInspect;
          this.addForm1.pipeInspect = res.data[0].pipeInspect;
          this.addForm1.HVACInspect = res.data[0].HVACInspect;
        });
      await this.$app
        .callFunction({
          name: "getFinalReport",
          data: {
            puweihao: this.addForm1.Shop + "-" + this.addForm1.Brand,
          },
        })
        .then((res) => {
          console.log("__________________", res);
          this.dataList1 = res.result.data;
          let list = [];
          let i = 0;
          for (i; i < this.dataList1.length; i++) {
            let event1 = {};
            event1["zhenggaixiangmu"] = this.dataList1[i].zhenggaixiangmu;
            event1["date"] = this.dataList1[i].date;
            event1["username"] = this.dataList1[i].username;
            event1["comment"] = this.dataList1[i].comment;
            this.fileIDs = this.dataList1[i].fileIDs;
            this.$cloudbase
              .getTempFileURL({
                fileList: this.fileIDs,
              })
              .then((res) => {
                this.temp = res.fileList;
                console.log(this.temp);
                let a = 0;
                this.tempFileURL = [];
                for (a; a < this.temp.length; a++) {
                  this.tempFileURL.push(this.temp[a].tempFileURL);
                }
                event1["fileIDs"] = this.tempFileURL;
                event1 = JSON.parse(JSON.stringify(event1));
                list.push(event1);
              });
          }
          this.dataList = list;
        })
        .catch((err) => {
          this.$message.error("发送失败！");
          console.log(err);
        });
    },

    print() {
      // 打印表单
      printJS({
        printable: "printForm",
        type: "html",
        targetStyles: "[*]",
        maxWidth: "100%",
      });
    },

    edit() {
      this.$refs.addFormRef1.validate((valid) => {
        if (!valid) return;
        this.loading = true;
        this.$app
          .callFunction({
            name: "updateShopInfo",
            data: {
              dataID: this.editeID,
              MallOT: this.addForm1.MallOT,
              Floor: this.addForm1.Floor,
              Shop: this.addForm1.Shop,
              Brand: this.addForm1.Brand,
              category: this.addForm1.category,
              condition: this.addForm1.condition,
              area: this.addForm1.area,
              PIC: this.addForm1.PIC,
              fittingAuth: this.addForm1.fittingAuth,
              wallInspect: this.addForm1.wallInspect,
              ceilInspect: this.addForm1.ceilInspect,
              finalInspect: this.addForm1.finalInspect,
              allowed: this.addForm1.allowed.toString(),
              design: this.addForm1.design,
              construct: this.addForm1.construct,
              edrawing: this.addForm1.edrawing,
              openInspection: this.addForm1.openInspection,
              info: this.addForm1.info,
              leasingPIC: this.addForm1.leasingPIC,
              shigongfuzeren: this.addForm1.shigongfuzeren,
              mobile: this.addForm1.mobile,
              puweihao: this.addForm1.Shop + "-" + this.addForm1.Brand,
              dangyan: this.addForm.dangyan,
              geqiangdaoding: this.addForm1.geqiangdaoding,
              waterInspect: this.addForm1.waterInspect,
              pipeInspect: this.addForm1.pipeInspect,
              HVACInspect: this.addForm1.HVACInspect,
            },
          })
          .then((res) => {
            this.$message.success("店铺信息修改成功");
            this.loading = false;
            this.editDialogVisible = false;
            this.getUserList();
          })
          .catch((err) => {
            this.$message.error("删除失败");
            console.log(err);
          });
      });
    },

    GetDateStr(AddDayCount) {
      var dd = new Date();
      dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1; //获取当前月份的日期
      var d = dd.getDate();
      return y + "-" + m + "-" + d;
    },

    async workinginProgress() {
      const db = this.$cloudbase.database();
      const _ = db.command;
      await this.$cloudbase
        .database()
        .collection("shopInfo")
        .limit(this.pageSize)
        .skip(this.pageSize * (this.pageNum - 1))
        .where({
          condition: "施工中",
        })
        .get()
        .then((res) => {
          console.log("用户列表：", res.data);
          this.userList = res.data;
        });
      await this.$cloudbase
        .database()
        .collection("shopInfo")
        .where({
          condition: "施工中",
        })
        .count()
        .then((res) => {
          console.log("用户列表长度：", res.total);
          this.total = res.total;
        });
    },

    async filterMall() {
      const db = this.$cloudbase.database();
      const _ = db.command;
      await this.$cloudbase
        .database()
        .collection("shopInfo")
        .limit(this.pageSize)
        .skip(this.pageSize * (this.pageNum - 1))
        .where({
          MallOT: "Mall",
        })
        .get()
        .then((res) => {
          console.log("用户列表：", res.data);
          this.userList = res.data;
        });
      await this.$cloudbase
        .database()
        .collection("shopInfo")
        .where({
          MallOT: "Mall",
        })
        .count()
        .then((res) => {
          console.log("用户列表长度：", res.total);
          this.total = res.total;
        });
    },

    async filterOT() {
      const db = this.$cloudbase.database();
      const _ = db.command;
      await this.$cloudbase
        .database()
        .collection("shopInfo")
        .limit(this.pageSize)
        .skip(this.pageSize * (this.pageNum - 1))
        .where({
          MallOT: "OT",
        })
        .get()
        .then((res) => {
          console.log("用户列表：", res.data);
          this.userList = res.data;
        });
      await this.$cloudbase
        .database()
        .collection("shopInfo")
        .where({
          MallOT: "OT",
        })
        .count()
        .then((res) => {
          console.log("用户列表长度：", res.total);
          this.total = res.total;
        });
    },

    switchPuweihao(value, row) {
      console.log(row);
      this.$app
        .callFunction({
          name: "updateStatus",
          data: {
            dataID: row._id,
            status: value,
          },
        })
        .then((res) => {
          this.$message.success("编号显示状态更新成功");
          this.getUserList();
        })
        .catch((err) => {
          this.$message.error("编号显示状态更新失败");
          this.getUserList();
          console.log(err);
        });
    },

    async exportList() {
      for (let i in this.userList) {
        this.MallOT = this.userList[i].MallOT;
        this.Floor = this.userList[i].Floor;
        this.Shop = this.userList[i].Shop;
        this.Brand = this.userList[i].Brand;
        this.category = this.userList[i].category;
        this.condition = this.userList[i].condition;
        this.area = this.userList[i].area;
        this.PIC = this.userList[i].PIC;
        this.fittingAuth = this.userList[i].fittingAuth;
        this.wallInspect = this.userList[i].wallInspect;
        this.ceilInspect = this.userList[i].ceilInspect;
        this.waterInspect = this.userList[i].waterInspect;
        this.pipeInspect = this.userList[i].pipeInspect;
        this.HVACInspect = this.userList[i].HVACInspect;
        this.finalInspect = this.userList[i].finalInspect;
        this.allowed = this.userList[i].allowed;
        this.construct = this.userList[i].construct;
        this.edrawing = this.userList[i].edrawing;
        this.openInspection = this.userList[i].openInspection;
        this.dangyan = this.userList[i].dangyan;
        this.geqiangdaoding = this.userList[i].geqiangdaoding;
        this.info = this.userList[i].info;
        this.leasingPIC = this.userList[i].leasingPIC;
        this.shigongfuzeren = this.userList[i].shigongfuzeren;
        this.mobile = this.userList[i].mobile;

        this.finalList.push({
          MallOT: this.MallOT,
          Floor: this.Floor,
          Shop: this.Shop,
          Brand: this.Brand,
          category: this.category,
          condition: this.condition,
          area: this.area,
          PIC: this.PIC,
          fittingAuth: this.fittingAuth,
          wallInspect: this.wallInspect,
          ceilInspect: this.ceilInspect,
          waterInspect: this.waterInspect,
          HVACInspect: this.HVACInspect,
          finalInspect: this.finalInspect,
          allowed: this.allowed,
          construct: this.construct,
          edrawing: this.edrawing,
          openInspection: this.openInspection,
          dangyan: this.dangyan,
          geqiangdaoding: this.geqiangdaoding,
          info: this.info,
          leasingPIC: this.leasingPIC,
          shigongfuzeren: this.shigongfuzeren,
          mobile: this.mobile,
        });
        console.log(this.finalList);
      }
      const column = [
        {
          title: "Mall/OT",
          key: "MallOT",
          type: "text",
          width: 310,
          height: 340,
        },
        {
          title: "楼层/区",
          key: "Floor",
          type: "text",
          width: 310,
          height: 340,
        },
        {
          title: "编号",
          key: "Shop",
          type: "text",
          width: 310,
          height: 340,
        },
        {
          title: "品牌",
          key: "Brand",
          type: "text",
        },
        {
          title: "业态",
          key: "category",
          type: "text",
          width: 310,
          height: 340,
        },
        {
          title: "状态",
          key: "condition",
          type: "text",
          width: 310,
          height: 340,
        },
        {
          title: "面积(㎡)",
          key: "area",
          type: "text",
        },
        {
          title: "装修组PIC",
          key: "PIC",
          type: "text",
          width: 310,
          height: 340,
        },
        {
          title: "装修许可证签批日期",
          key: "fittingAuth",
          type: "text",
          width: 310,
          height: 340,
        },
        {
          title: "隔墙验收",
          key: "wallInspect",
          type: "text",
          width: 310,
          height: 340,
        },
        {
          title: "吊顶验收",
          key: "ceilInspect",
          type: "text",
          width: 310,
          height: 340,
        },
        {
          title: "给水管打压",
          key: "waterInspect",
          type: "text",
          width: 310,
          height: 340,
        },
        {
          title: "空调水管清洗/打压",
          key: "HVACInspect",
          type: "text",
          width: 310,
          height: 340,
        },
        {
          title: "竣工验收",
          key: "finalInspect",
          type: "text",
          width: 310,
          height: 340,
        },
        {
          title: "可施工项目",
          key: "allowed",
          type: "text",
        },
        {
          title: "施工图审核次数",
          key: "construct",
          type: "text",
        },
        {
          title: "消防图纸电子版",
          key: "edrawing",
          type: "text",
        },
        {
          title: "开业检查",
          key: "openInspection",
          type: "text",
        },
        {
          title: "挡烟垂壁",
          key: "dangyan",
          type: "text",
        },
        {
          title: "隔墙是否到顶",
          key: "geqiangdaoding",
          type: "text",
        },
        {
          title: "备注",
          key: "info",
          type: "text",
        },
        {
          title: "租赁PIC",
          key: "leasingPIC",
          type: "text",
        },
        {
          title: "施工负责人",
          key: "shigongfuzeren",
          type: "text",
        },
        {
          title: "电话",
          key: "mobile",
          type: "text",
        },
      ];
      /** column数据的说明 */
      // 1.title为列名
      // 2.key为data数据每个对象对应的key
      // 3.若为图片格式, 需要加type为image的说明,并且可以设置图片的宽高
      const excelName = "装修情况汇总";
      exportExcel(column, this.finalList, excelName);
    },
  },
};
</script>

<style lang="less" scoped>
#printTest table {
  font-family: "宋体";
  border-collapse: collapse;
  width: 99.5%;
}
#printTest table thead th {
  height: 40px;
  font-size: 13px;
  /* width: 10px; */
  text-align: center;
  border: 1px solid black;
}
#printTest table tbody tr {
  font-size: 13px;
  border: 1px solid black;
  height: 40px;
  text-align: center;
}
#printTest table tbody td {
  font-size: 13px;
  text-align: center;
  border: 1px solid black;
}
table,
table tr th,
table tr td {
  border: 1px solid;
}
table {
  line-height: 25px;
  text-align: center;
  border-collapse: collapse;
}
.table-box {
  border: 1px solid;
}
.table-page {
  width: 210mm;
  height: 283mm;
  margin: 0 auto;
  margin-top: 10mm;
}
.h1-page {
  position: relative;
  font-weight: 400;
  margin: 0 auto;
  text-align: center;
  top: 20mm;
}
.align-left {
  text-align: left;
}
.text-underline {
  text-decoration: underline;
  text-align: center;
  color: red;
}
.image1 {
  margin: 10px;
}
.title1 {
  font-weight: 700;
  font-size: 20px;
}
</style>