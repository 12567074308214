<template>
  <div>
    <div class="card-panel-text">PIC待跟进整改排行</div>
    <dv-scroll-ranking-board
      :config="config"
      style="width: 500px; height: 300px"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList: [],
      config: {},
      comment1: "",
    };
  },

  async created() {},

  async mounted() {
    await this.getUserList();
    this.config = {
      data: this.dataList,
      unit: "个",
      sort: true,
      rowNum: 6,
  }},

  methods: {
    async getUserList() {
      await this.$app
        .callFunction({
          name: "PICStatistic",
        })
        .then((res) => {
          console.log(res);
          this.dataList = res.result.list;
          let list = [];
          for (let i = 0, len = this.dataList.length; i < len; i++) {
            let event1 = {};
            event1["name"] = this.dataList[i]._id;
            event1["value"] = this.dataList[i].value;
            event1 = JSON.parse(JSON.stringify(event1));
            list.push(event1);
          }
          this.dataList = list;
        })
        .catch((err) => {
          this.$message.error("发送失败！");
          console.log(err);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.dv-scroll-board {
  color: #333;
  /deep/ .header-item {
    font-size: 1em;
    text-align: center;
    white-space: nowrap;
    color: #fff !important;
  }
  .rows {
    .row-item {
      text-align: center;
      line-height: 2rem;
      .ceil {
        color: #3c3f52;
        font-size: 1em;
        i {
          font-size: 0.5em;
        }
      }
      .ceil:nth-child(2) {
        color: #eb5a6d !important;
        font-size: 1.1em;
        width: 25%;
      }
    }
  }
}
.test {
  width: 10px;
  background-size: 10% 10%;
  word-break: keep-all; /* 不换行 */
  white-space: nowrap; /* 不换行 */
  background: #eb5a6d;
}
</style>