<template>
  <div>
    <panel-group />
    <el-row style="background: #fff; padding: 16px 16px 0; margin-bottom: 32px">
      <screen />
    </el-row>
    <el-row :gutter="32">
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <!-- <raddar-chart /> -->
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <pie-chart />
        </div>
      </el-col>
            <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper1">
          <to-do-list />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper1">
          <bar-chart />
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="8">
      <el-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: 12 }"
        :xl="{ span: 12 }"
        style="padding-right: 8px; margin-bottom: 30px"
      >
      </el-col>
      <el-col
        :xs="{ span: 24 }"
        :sm="{ span: 12 }"
        :md="{ span: 12 }"
        :lg="{ span: 6 }"
        :xl="{ span: 6 }"
        style="margin-bottom: 30px"
      >
      </el-col>
      <!-- <el-col
        :xs="{ span: 24 }"
        :sm="{ span: 12 }"
        :md="{ span: 12 }"
        :lg="{ span: 6 }"
        :xl="{ span: 6 }"
        style="margin-bottom: 30px"
      >
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
import PanelGroup from "./components/PanelGroup";
import Screen from "./components/Screen";
import BarChart from "./components/BarChart";
import PieChart from "./components/PieChart";
import ToDoList from "./components/ToDoList.vue";
export default {
  components: {
    PanelGroup,
    Screen,
    BarChart,
    PieChart,
    ToDoList,
    BarChart,
  },

  data() {
    return {
      dataList: [],
      config: {},
    };
  },

  async created() {
    const loginState = this.$cloudbase.auth().hasLoginState();
    if (loginState) {
      // 登录态有效
      console.log("登录状态有效");
    } else {
      console.log("登录状态无效");
      this.$router.push("/login");
    }
  },

  async mounted() {
    // await this.getUserList();
    // this.config = {
    //   data: this.dataList,
    //   header: ["铺位号", "施工项目", "整改项目"],
    //   index: true,
    //   align: ["center"],
    //   columnWidth: [50, 150, 100, 300],
    //   waitTime: 3000,
    // };
    // console.log(this.config.data);
  },

  methods: {
    // async getUserList() {
    //   const db = this.$cloudbase.database();
    //   const _ = db.command;
    //   await this.$cloudbase
    //     .database()
    //     .collection("image")
    //     .where({
    //       zhenggaixiangmu: _.neq(""),
    //       complete: _.eq("").or(_.eq("no")).or(_.eq("[]")),
    //     })
    //     .orderBy("createTime", "desc")
    //     .get()
    //     .then((res) => {
    //       console.log("用户列表：", res.data);
    //       let list = res.data;
    //       if (list.length > 0) {
    //         for (let i in list) {
    //           let puweihao = list[i].puweihao;
    //           let username = list[i].username;
    //           let zhenggaixiangmu = list[i].zhenggaixiangmu;
    //           this.dataList.push([puweihao, username, zhenggaixiangmu]);
    //         }
    //       }
    //     });
    // },
    // async getUserList() {
    //   const db = this.$cloudbase.database();
    //   const _ = db.command;
    //   await this.$cloudbase
    //     .database()
    //     .collection("image")
    //     .where({
    //       zhenggaixiangmu: _.neq(""),
    //       complete: _.eq("").or(_.eq("no")).or(_.eq("[]")),
    //     })
    //     .orderBy("createTime", "desc")
    //     .watch({
    //       onChange: function (snapshot) {
    //         console.log("用户列表：", res.data);
    //         let list = res.data;
    //         if (list.length > 0) {
    //           for (let i in list) {
    //             let puweihao = list[i].puweihao;
    //             let shigongxiangmu = list[i].shigongxiangmu;
    //             let zhenggaixiangmu = list[i].zhenggaixiangmu;
    //             this.dataList.push([puweihao, shigongxiangmu, zhenggaixiangmu]);
    //           }
    //         }
    //       },
    //       onError: function (err) {
    //         console.error("the watch closed because of error", err);
    //       },
    //     });
    // },
  },
};
</script>

<style lang="less" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: rgb(240, 242, 245);
  position: relative;
  .github-corner {
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }
  .chart-wrapper {
    background: rgb(3, 3, 3);
    padding: 16px 16px 0;
    margin-bottom: 32px;
    触发 .chart-wrapper {
      padding: 8px;
    }
  }
}
</style>