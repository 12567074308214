<template>
  <div>
    <dv-scroll-board :config="config" style="width: 100%; height: 300px" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [],
      config: {},
      comment1: "",
    };
  },

  async created() {
  },

  async mounted() {
    await this.getUserList();
    this.config = {
      data: this.dataList,
      header: [
        "铺位号",
        "上传日期",
        "上传人",
        "施工项目",
        "整改项目",
        "最新跟进情况",
      ],
      index: true,
      headerBGC: "#409EFF", // 表头背景色
      oddRowBGC: "#FFF", // 奇数行背景色
      evenRowBGC: "#f5f5f5", // 偶数行背景色
      columnWidth: [50, 200, 200, 100],
      waitTime: 2000,
      align: ["center"],
    };
    console.log("--------", this.config.data);
  },

  methods: {
    async getUserList() {
      const db = this.$cloudbase.database();
      const _ = db.command;
      await this.$cloudbase
        .database()
        .collection("image")
        .where({
          zhenggaixiangmu: _.neq(""),
          complete: _.eq("").or(_.eq("no")).or(_.eq("[]")),
        })
        .orderBy("createTime", "desc")
        .get()
        .then((res) => {
          console.log("用户列表：", res.data);
          let list = res.data;
          if (list.length > 0) {
            for (let i in list) {
              let puweihao = list[i].puweihao;
              let username = list[i].username;
              let date = list[i].date;
              let zhenggaixiangmu = list[i].zhenggaixiangmu;
              let shigongxiangmu = list[i].shigongxiangmu;
              let comment = list[i].comment;
              let commentLength = comment.length;
              if (comment.length > 0) {
                this.comment1 = comment[commentLength - 1].content;
                console.log("--------------------------");
              } else {
                this.comment1 = "暂未跟进";
              }
              this.dataList.push([
                puweihao,
                date,
                username,
                shigongxiangmu,
                zhenggaixiangmu,
                this.comment1,
              ]);
            }
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.dv-scroll-board {
  color: #333;
  /deep/ .header-item {
    font-size: 1em;
    text-align: center;
    white-space: nowrap;
    color: #fff !important;
  }
  .rows {
    .row-item {
      text-align: center;
      line-height: 2rem;
      .ceil {
        color: #3c3f52;
        font-size: 1em;
        i {
          font-size: 0.5em;
        }
      }
      .ceil:nth-child(2) {
        color: #eb5a6d !important;
        font-size: 1.1em;
        width: 25%;
      }
    }
  }
}
.test {
  width: 10px;
  background-size: 10% 10%;
  word-break: keep-all; /* 不换行 */
  white-space: nowrap; /* 不换行 */
  background: #eb5a6d;
}
</style>