<template>
  <el-row :gutter="40" class="panel-group">
    <el-col span="4" class="card-panel-col">
      <div class="card-panel">
        <div class="card-panel-description">
          <div class="card-panel-text">总巡检记录数量</div>
          <dv-digital-flop
            :config="config1"
            style="width: 200px; height: 50px"
          />
        </div>
      </div>
    </el-col>
    <el-col span="4" class="card-panel-col">
      <div class="card-panel">
        <div class="card-panel-description">
          <div class="card-panel-text">整改数量</div>
          <dv-digital-flop
            :config="config2"
            style="width: 200px; height: 50px"
          />
        </div>
      </div>
    </el-col>
    <el-col span="4" class="card-panel-col">
      <div class="card-panel">
        <div class="card-panel-description">
          <div class="card-panel-text">待消项整改数量</div>
          <dv-digital-flop
            :config="config3"
            style="width: 200px; height: 50px"
          />
        </div>
      </div>
    </el-col>
    <el-col span="4" class="card-panel-col">
      <div class="card-panel">
        <div class="card-panel-description">
          <div class="card-panel-text">今日验收数量</div>
          <dv-digital-flop
            :config="config4"
            style="width: 200px; height: 50px"
          />
        </div>
      </div>
    </el-col>
    <el-col span="4" class="card-panel-col">
      <div class="card-panel">
        <div class="card-panel-description">
          <div class="card-panel-text">Mall施工中店铺数量</div>
          <dv-digital-flop
            :config="config5"
            style="width: 200px; height: 50px"
          />
        </div>
      </div>
    </el-col>
    <el-col span="4" class="card-panel-col">
      <div class="card-panel">
        <div class="card-panel-description">
          <div class="card-panel-text">OT施工中店铺数量</div>
          <dv-digital-flop
            :config="config6"
            style="width: 200px; height: 50px"
          />
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      totalNum: "",
      config1: {},
      config2: {},
      config3: {},
      config4: {},
      config5: {},
      config6: {},
      dateInfo: "",
      total: "",
    };
  },

  async created() {
    this.dateInfo = this.GetDateStr(0);
    this.getDataList();
  },

  methods: {
    async getDataList() {
      const db = this.$cloudbase.database();
      const _ = db.command;
      await this.$cloudbase
        .database()
        .collection("image")
        .count()
        .then((res) => {
          console.log("巡场列表长度：", res.total);
          this.config1 = {
            number: [res.total],
            content: "{nt}条",
            textAlign: "left",
            style: {
              fontSize: 30,
              fill: "#409EFF",
            },
          };
        });
      await this.$cloudbase
        .database()
        .collection("image")
        .where({
          zhenggaixiangmu: _.neq(""),
        })
        .count()
        .then((res) => {
          console.log("巡场列表长度：", res.total);
          this.config2 = {
            number: [res.total],
            content: "{nt}条",
            textAlign: "left",
            style: {
              fontSize: 30,
              fill: "#409EFF",
            },
          };
        });
      await this.$cloudbase
        .database()
        .collection("image")
        .where({
          zhenggaixiangmu: _.neq(""),
          complete: _.eq("").or(_.eq("no")).or(_.eq("[]")),
        })
        .count()
        .then((res) => {
          console.log("巡场列表长度：", res.total);
          this.config3 = {
            number: [res.total],
            content: "{nt}条",
            textAlign: "left",
            style: {
              fontSize: 30,
              fill: "#F56C6C",
            },
          };
        });
      await this.$app
        .callFunction({
          name: "getReserveData",
          data: {
            data1: this.dateInfo,
          },
        })
        .then((res) => {
          this.userList = res.result.data;
          this.total = this.userList.length;
          this.config4 = {
            number: [this.total],
            content: "{nt}个",
            textAlign: "left",
            style: {
              fontSize: 30,
              fill: "#409EFF",
            },
          };
        })
        .catch((err) => {});
      await this.$cloudbase
        .database()
        .collection("shopInfo")
        .where({
          condition: "施工中",
          MallOT: "Mall",
        })
        .count()
        .then((res) => {
          console.log("巡场列表长度：", res.total);
          this.config5 = {
            number: [res.total],
            content: "{nt}家",
            textAlign: "left",
            style: {
              fontSize: 30,
              fill: "#008B45",
            },
          };
        });
      await this.$cloudbase
        .database()
        .collection("shopInfo")
        .where({
          condition: "施工中",
          MallOT: "OT",
        })
        .count()
        .then((res) => {
          console.log("巡场列表长度：", res.total);
          this.config6 = {
            number: [res.total],
            content: "{nt}家",
            textAlign: "left",
            style: {
              fontSize: 30,
              fill: "#008B45",
            },
          };
        });
    },

    GetDateStr(AddDayCount) {
      var dd = new Date();
      dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1; //获取当前月份的日期
      var d = dd.getDate();
      return y + "-" + m + "-" + d;
    },
  },
};
</script>

<style lang="less" scoped>
.panel-group {
  margin-top: 18px;
  .card-panel-col {
    margin-bottom: 32px;
  }
  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: rgb(255, 255, 255);
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.05);
    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }
      .icon-people {
        background: #40c9c6;
      }
      .icon-message {
        background: #36a3f7;
      }
      .icon-money {
        background: #f4516c;
      }
      .icon-shopping {
        background: #34bfa3;
      }
    }
    .icon-people {
      color: #40c9c6;
    }
    .icon-message {
      color: #36a3f7;
    }
    .icon-money {
      color: #f4516c;
    }
    .icon-shopping {
      color: #34bfa3;
    }
    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }
    .card-panel-icon {
      float: left;
      font-size: 48px;
    }
    .card-panel-description {
      float: center;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;
      .card-panel-text {
        line-height: 18px;
        color: rgb(0, 0, 0);
        font-size: 20px;
        margin-bottom: 12px;
        align-items: center;
      }
      .card-panel-num {
        float: center;
        align-items: center;
        font-size: 20px;
      }
    }
  }
}
@media (max-width: 550px) {
  .card-panel-description {
    display: none;
  }
  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
.numText {
  font-size: 20px;
}
</style>