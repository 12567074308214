<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item>
      <el-breadcrumb-item>活动列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input
            placeholder="请输入内容"
            v-model="searchFeild"
            clearable
            @clear="getUserList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-date-picker
            v-model="dateInfo"
            align="right"
            type="date"
            placeholder="选择日期"
            @change="getUserList"
            value-format="yyyy-M-d"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-table :data="userList" border stripe :key="userList._id">
        <el-table-column
          align="center"
          type="index"
          label="#"
        ></el-table-column>
        <el-table-column
          prop="puweihao"
          align="center"
          label="铺位号"
        ></el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="联系人"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          align="center"
          label="电话"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="yanshouxiangmu"
          label="验收项目"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="date"
          label="日期"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="time"
          label="时间"
        ></el-table-column>
        <el-table-column align="center" prop="status" label="状态">
          <template slot-scope="scope">
            <span align="center" v-if="scope.row.status == 'cancel'">取消</span>
            <span align="center" v-if="!scope.row.status">正常</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="result"
          label="验收结果"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="info"
          label="备注"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="people"
          label="验收人"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 25, 50]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      title="添加用户"
      :visible.sync="addDialogVisible"
      width="30%"
      v-loading="loading"
      @close="addDialogClosed"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="80px"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addForm.username"></el-input>
        </el-form-item>
        <el-form-item label="初始密码" prop="password">
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
        <el-form-item label="用户权限" prop="auth">
          <el-select v-model="addForm.auth" placeholder="请选择权限"
            >>
            <el-option label="超级管理员" value="true_super"></el-option>
            <el-option label="管理员" value="true"></el-option>
            <el-option label="普通" value="false"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">添 加</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userList: [],
      total: 0,
      pageSize: 10,
      pageNum: 1,
      searchFeild: "",
      addDialogVisible: false,
      addForm: { name: "", username: "", password: "", auth: "" },
      addFormRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        password: [
          { required: true, message: "请输入初始密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "初始密码长度需在6-15个字符之间",
            trigger: "blur",
          },
        ],
      },
      loading: false,
      value: "",
      dateInfo: "",
    };
  },
  async created() {
    const loginState = this.$cloudbase.auth().hasLoginState();
    if (loginState) {
      // 登录态有效
      console.log("登录状态有效");
      this.dateInfo = this.GetDateStr(0);
      this.getUserList(this.date);
    } else {
      console.log("登录状态无效");
      this.$router.push("/login");
    }
  },
  methods: {
    handleSizeChange(newSize) {
      console.log("每页显示数量", newSize);
      this.pageSize = newSize;
      this.getUserList();
    },

    handleCurrentChange(newPage) {
      console.log("页码", newPage);
      this.pageNum = newPage;
      this.getUserList();
    },

    async getUserList() {
      console.log(this.dateInfo);
      let date = this.dateInfo;
      await this.$app
        .callFunction({
          name: "getReserveData",
          data: {
            data1: this.dateInfo,
          },
        })
        .then((res) => {
          this.userList = res.result.data;
          this.total = this.userList.length;
          console.log(res);
        })
        .catch((err) => {
          this.$message.error("发送失败！");
          console.log(err);
        });
    },
    
    GetDateStr(AddDayCount) {
      var dd = new Date();
      dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1; //获取当前月份的日期
      var d = dd.getDate();
      return y + "-" + m + "-" + d;
    },

    search() {
      console.log("-----------", this.searchFeild);
      const db = this.$cloudbase.database();
      const _ = db.command;
      this.$cloudbase
        .database()
        .collection("user")
        .limit(this.pageSize)
        .skip(this.pageSize * (this.pageNum - 1))
        .where(
          _.or([
            {
              name: db.RegExp({
                regexp: this.searchFeild,
                options: "i",
              }),
            },
            {
              username: db.RegExp({
                regexp: this.searchFeild,
                options: "i",
              }),
            },
          ])
        )
        .get()
        .then((res) => {
          console.log("用户列表：", res.data);
          this.userList = res.data;
        });
    },

    addUser() {
      this.$refs.addFormRef.validate((valid) => {
        if (!valid) return;
        this.loading = true;
        const db = this.$cloudbase.database();
        this.$cloudbase
          .database()
          .collection("user")
          .add({
            name: this.addForm.name,
            username: this.addForm.username,
            password: this.addForm.password,
            auth: this.addForm.auth,
          })
          .then((res) => {
            this.loadinvg = false;
            console.log("用户列表：", res);
            this.$message.success("用户添加成功");
            this.addDialogVisible = false;
            this.getUserList();
          });
      });
    },

    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },

    tableRowClassName(row) {
      if (row.status == "cancel") {
        console.log("warning");
        return "warning-row";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-table .warning-row {
  background: red;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>