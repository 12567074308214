<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>巡场记录</el-breadcrumb-item>
      <el-breadcrumb-item>待消项记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-table :data="dataList" border stripe>
        <!-- <el-table-column type="index" label="#"></el-table-column> -->
        <el-table-column prop="puweihao" label="编号"></el-table-column>
        <el-table-column prop="date" label="上传时间"></el-table-column>
        <el-table-column
          prop="shigongxiangmu"
          label="施工项目"
        ></el-table-column>
        <el-table-column
          prop="zhenggaixiangmu"
          label="整改项目"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row._id)"
            ></el-button>
            <el-button
              type="warning"
              icon="el-icon-setting"
              size="mini"
            ></el-button>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除用户"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 25, 50]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog title="详情" :visible.sync="editDialogVisible" width="50%">
      <section id="printForm" ref="form">
        <!-- <h1 class="h1-page">昆明恒隆广场（商场）商铺（工程）整改通知单</h1> -->
        <table class="table-page">
          <tbody>
            <tr>
              <td colspan="4" class="title1">昆明恒隆广场（商场）商铺（工程）整改通知单</td>
            </tr>
            <tr>
              <td>商铺编号</td>
              <td>{{ this.dataList1[0].puweihao }}</td>
              <td>商铺名称</td>
              <td>{{ this.dataList1[0].puweihao }}</td>
            </tr>
            <tr>
              <td colspan="1">租户名称</td>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td>检查日期</td>
              <td>{{ this.dataList1[0].date }}</td>
              <td>检查时间</td>
              <td>{{ this.dataList1[0].date }}</td>
            </tr>
            <tr>
              <td class="align-left" colspan="4">
                经本商场现场管理人员检查发现 现场存在如下问题： 
                新建隔墙位置与报审图纸不符；  隔墙安装不牢固； 
                隔墙遮挡幕墙玻璃、遮挡散热器；  地面恢复不到位； 
                天花恢复不到位；  租户门出入口与报审图纸不符； 
                公共区域精装设备、设施有损坏；  吊筋不是独立吊筋； 
                系统图与施工现场不符； 
                使用导线非低烟无卤线,使用导线截面与系统图设计截面不符； 
                管线缺少跨接地线、部分管线未固定,部分导线未穿保护管或保护管不符合要求；
                 隔墙砌筑遮挡灯具；  应急照明回路非独立回路； 
                配电箱内无回路标识；  进入资讯房的管线不符合要求； 
                摄像机变压器不可以放入棚内；  强弱电线缆要分开； 
                机柜线缆布线工艺不符合要求；  消防电线缆不符合消防规范； 
                烟感的保护半径不符合消防规范相关；  机电房内有喷淋头； 
                风机盘管下方无检修口；  空调出风口位置与报审图纸不符； 
                采暖、冷冻水管保温不完成；  风机盘管未安装静压箱； 
                风机盘管未安装过滤网；  风机盘管未做棚下回风； 
                风机盘管冷冻水、采暖水供回水管线接驳错误； 
                采暖、冷冻水管未做打压测试。 
                采暖、冷冻水管电磁阀控制线未连接；  空调出风口软连接过长； 
                空调出风口正对办公人员；  风管道保温不全； 
                排烟口位置与报审图纸不符；  补风口位置与报审图纸不符； 
                喷淋头位置与报审图纸不符；  喷淋头保护半径内有遮挡； 
                排油烟管道设备位置、参数与报审图纸不符； 
                餐饮商铺料理台及排水管前未安装废弃阻隔器及隔油池；
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div class="text-underline">其他：{{ this.dataList1[0].zhenggaixiangmu }}</div>
                
              </td>
            </tr>
            <tr>
              <td class="align-left" colspan="4">
                敬请租户于 年 月 日之前完成上述违规现象或者不合格问题的整改。
              </td>
            </tr>
            <tr>
              <td class="align-left" colspan="4">
                检查人：{{ this.dataList1[0].username }}
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <el-image
                  v-for="(ele, index) in this.tempFileURL"
                  :key="index"
                  :src="ele"
                  style="width: 300px; height: 300px"
                  class="image1"
                ></el-image>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="print">打 印</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import printTemplate from "print-template";
export default {
  data() {
    return {
      dataList: [],
      dataList1: [],
      searchFeild: "",
      total: 0,
      pageSize: 10,
      pageNum: 1,
      editDialogVisible: false,
      fileIDs: "",
      tempFileURL: "",
      dataList1: [{ puweihao: "", date: "", username: "" }],
      tempFileURL: [],
    };
  },

  async created() {
    const loginState = this.$cloudbase.auth().hasLoginState();
    if (loginState) {
      // 登录态有效
      console.log("登录状态有效");
      this.getDataList();
      console.log(this.dataList1);
    } else {
      console.log("登录状态无效");
      this.$router.push("/login");
    }
  },

  methods: {
    handleSizeChange(newSize) {
      console.log("每页显示数量", newSize);
      this.pageSize = newSize;
      this.getDataList();
    },

    handleCurrentChange(newPage) {
      console.log("页码", newPage);
      this.pageNum = newPage;
      this.getDataList();
    },

    async getDataList() {
      const db = this.$cloudbase.database();
      const _ = db.command;
      await this.$cloudbase
        .database()
        .collection("image")
        .limit(this.pageSize)
        .skip(this.pageSize * (this.pageNum - 1))
        .where({
          zhenggaixiangmu: _.neq(""),
          complete: _.eq("").or(_.eq("no")).or(_.eq("[]")),
        })
        .orderBy("createTime", "desc")
        .get()
        .then((res) => {
          console.log("巡场列表：", res.data);
          this.dataList = res.data;
        });
      await this.$cloudbase
        .database()
        .collection("image")
        .where({
          zhenggaixiangmu: _.neq(""),
          complete: _.eq("").or(_.eq("no")).or(_.eq("[]")),
        })
        .count()
        .then((res) => {
          console.log("巡场列表长度：", res.total);
          this.total = res.total;
        });
    },

    async showEditDialog(id) {
      this.tempFileURL = [];
      this.editDialogVisible = true;
      const db = this.$cloudbase.database();
      const _ = db.command;
      await this.$cloudbase
        .database()
        .collection("image")
        .where({
          _id: id,
        })
        .get()
        .then((res) => {
          console.log("巡场列表：", res.data);
          this.dataList1 = res.data;
          console.log(this.dataList1);
          this.fileIDs = res.data[0].fileIDs;
          this.$cloudbase
            .getTempFileURL({
              fileList: this.fileIDs,
            })
            .then((res) => {
              this.temp = res.fileList;
              let i = 0;
              for (i; i < this.temp.length; i++) {
                this.tempFileURL.push(this.temp[i].tempFileURL);
              }
              console.log(this.tempFileURL);
            });
        });
    },

    handleNodeClick(data) {
      console.log(data);
    },

    print() {
      // 打印表单
      printJS({ printable: "printForm", type: "html", targetStyles: "[*]", maxWidth:'100%' });
    },
  },
};
</script>

<style lang="less" scoped>
#printTest table {
  font-family: "宋体";
  border-collapse: collapse;
  width: 99.5%;
}
#printTest table thead th {
  height: 40px;
  font-size: 13px;
  /* width: 10px; */
  text-align: center;
  border: 1px solid black;
}
#printTest table tbody tr {
  font-size: 13px;
  border: 1px solid black;
  height: 40px;
  text-align: center;
}
#printTest table tbody td {
  font-size: 13px;
  text-align: center;
  border: 1px solid black;
}
table,
table tr th,
table tr td {
  border: 1px solid;
}
table {
  line-height: 25px;
  text-align: center;
  border-collapse: collapse;
}
.table-box {
  border: 1px solid;
}
.table-page {
  width: 210mm;
  height: 283mm;
  margin: 0 auto;
  margin-top: 10mm;
  
}
.h1-page {
  position: relative;
  font-weight: 400;
  margin: 0 auto;
  text-align: center;
  top: 20mm;
}
.align-left {
  text-align: left;
}
.text-underline {
  text-decoration: underline;
  text-align: left;
  color: red;
}
.image1 {
  margin: 10px;
}
.title1{
  font-weight: 700;
  font-size: 20px;
}
</style>