<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item>
      <el-breadcrumb-item>活动列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input
            placeholder="请输入内容"
            v-model="searchFeild"
            clearable
            @clear="getUserList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加用户</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="userList" border stripe>
        <el-table-column
          align="center"
          type="index"
          label="#"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="姓名"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="username"
          label="用户名"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="puweihao"
          label="店铺编号"
        ></el-table-column>
        <el-table-column align="center" prop="auth" label="授权状态">
          <template slot-scope="scope">
            <span v-if="scope.row.auth == 'true_super'">超级管理员</span>
            <span v-if="scope.row.auth == 'true'">管理员</span>
            <span v-if="scope.row.auth == 'false'">普通</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row._id)"
            ></el-button>
            <el-button
              type="warning"
              icon="el-icon-setting"
              size="mini"
            ></el-button>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除用户"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 25, 50]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      title="添加用户"
      :visible.sync="addDialogVisible"
      width="30%"
      v-loading="loading"
      @close="addDialogClosed"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="80px"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addForm.username"></el-input>
        </el-form-item>
        <el-form-item label="初始密码" prop="password">
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
        <!-- <el-form-item label="店铺编号" prop="puweihao">
          <el-input v-model="addForm.puweihao"></el-input>
        </el-form-item> -->
        <el-form-item label="店铺编号" prop="puweihao">
          <el-select v-model="addForm.puweihao" placeholder="请选择店铺编号">
            <el-option
              v-for="item in options"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">添 加</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改用户信息"
      :visible.sync="editDialogVisible"
      width="30%"
      v-loading="loading"
      @close="editDialogClosed"
    >
      <el-form
        :model="addForm1"
        :rules="addFormRules"
        ref="addFormRef1"
        label-width="80px"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="addForm1.name"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addForm1.username"></el-input>
        </el-form-item>
        <el-form-item label="初始密码" prop="password">
          <el-input v-model="addForm1.password"></el-input>
        </el-form-item>
        <el-form-item label="店铺编号" prop="puweihao">
          <el-input v-model="addForm1.puweihao"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="edit">修 改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userList: [],
      id: "",
      total: 0,
      pageSize: 10,
      pageNum: 1,
      searchFeild: "",
      addDialogVisible: false,
      editDialogVisible: false,
      addForm: { name: "", username: "", password: "", puweihao: "" },
      addForm1: { name: "", username: "", password: "", puweihao: "" },
      addFormRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        puweihao: [
          { required: true, message: "请输入店铺编号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入初始密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "初始密码长度需在6-15个字符之间",
            trigger: "blur",
          },
        ],
      },
      loading: false,
      value: "",
      options: [],
    };
  },
  async created() {
    const loginState = this.$cloudbase.auth().hasLoginState();
    if (loginState) {
      // 登录态有效
      console.log("登录状态有效");
      this.getUserList();
      this.getPuweihao();
    } else {
      console.log("登录状态无效");
      this.$router.push("/login");
    }
  },
  methods: {
    handleSizeChange(newSize) {
      console.log("每页显示数量", newSize);
      this.pageSize = newSize;
      this.getUserList();
    },

    handleCurrentChange(newPage) {
      console.log("页码", newPage);
      this.pageNum = newPage;
      this.getUserList();
    },

    async getPuweihao() {
      await this.$app
        .callFunction({
          name: "getResPuweihao",
        })
        .then((res) => {
          this.options = res.result.data.puweihao;
          console.log(this.options);
        })
        .catch((err) => {
          this.$message.error("发送失败！");
          console.log(err);
        });
    },

    async getUserList() {
      await this.$app
        .callFunction({
          name: "getResUser",
          data: {
            pageSize: this.pageSize,
            pageNum: this.pageNum,
          },
        })
        .then((res) => {
          this.userList = res.result.data;
        })
        .catch((err) => {
          console.log(err);
        });
      await this.$app
        .callFunction({
          name: "getResUserLength",
        })
        .then((res) => {
          console.log(res);
          this.total = res.result.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    search() {
      console.log("-----------", this.searchFeild);
      const db = this.$cloudbase.database();
      const _ = db.command;
      this.$cloudbase
        .database()
        .collection("user")
        .limit(this.pageSize)
        .skip(this.pageSize * (this.pageNum - 1))
        .where(
          _.or([
            {
              name: db.RegExp({
                regexp: this.searchFeild,
                options: "i",
              }),
            },
            {
              username: db.RegExp({
                regexp: this.searchFeild,
                options: "i",
              }),
            },
          ])
        )
        .get()
        .then((res) => {
          console.log("用户列表：", res.data);
          this.userList = res.data;
        });
    },

    async addUser() {
      this.loading = true;
      await this.$app
        .callFunction({
          name: "addResUser",
          data: {
            name: this.addForm.name,
            username: this.addForm.username,
            password: this.addForm.password,
            puweihao: this.addForm.puweihao,
          },
        })
        .then((res) => {
          this.userList = res.result.data;
          this.getUserList();
          this.loadinvg = false;
          this.$message.success("用户添加成功");
          this.addDialogVisible = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },

    editDialogClosed() {
      this.$refs.addFormRef1.resetFields();
    },

    async showEditDialog(id) {
      this.editDialogVisible = true;
      this.id = id;
      console.log(this.id);
      await this.$app
        .callFunction({
          name: "getResUserDetail",
          data: {
            dataID: this.id,
          },
        })
        .then((res) => {
          console.log(res.result);
          this.addForm1.name = res.result.data[0].name;
          this.addForm1.username = res.result.data[0].username;
          this.addForm1.password = res.result.data[0].password;
          this.addForm1.auth = res.result.data[0].identity;
          this.addForm1.puweihao = res.result.data[0].puweihao;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    edit() {
      this.$refs.addFormRef1.validate((valid) => {
        if (!valid) return;
        this.loading = true;
        this.$app
          .callFunction({
            name: "updateResUser",
            data: {
              id: this.id,
              name: this.addForm1.name,
              username: this.addForm1.username,
              password: this.addForm1.password,
              puweihao: this.addForm1.puweihao,
            },
          })
          .then((res) => {
            this.loading = false;
            console.log(res);
            this.$message.success("更新成功");
            this.editDialogVisible = false;
            this.getUserList();
          })
          .catch((err) => {
            this.$message.error("更新失败");
            console.log(err);
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>