<template>
  <div>
    <el-row :gutter="500">
      <el-col :span="6"> <div id="main" ref="chart"></div></el-col>

      <el-col :span="6"> <div id="main" ref="chart1"></div></el-col>

      <el-col :span="6"> <div id="main" ref="chart2"></div></el-col>
    </el-row>
    <el-row :gutter="500">
      <el-col :span="6"> <div id="main" ref="chart3"></div></el-col>
    </el-row>
  </div>
</template>

<script>
var echarts = require("echarts");
export default {
  data() {
    return {
      data: "",
      a: "g",
    };
  },

  async created() {
    const loginState = this.$cloudbase.auth().hasLoginState();
    if (loginState) {
      // 登录态有效
      console.log("登录状态有效");
    } else {
      console.log("登录状态无效");
      this.$router.push("/login");
    }
  },

  async mounted() {
    await this.conditionGraph();
    await this.floorGraph();
    await this.categoryGraph();
    await this.PICGraph();
    this.drawLine();
    this.drawLine1();
    this.drawLine2();
    this.drawLine3();
  },

  methods: {
    async conditionGraph() {
      await this.$app
        .callFunction({
          name: "conditionGraph",
        })
        .then((res) => {
          console.log(res);
          this.dataList = res.result.list;
          let list_name = [];
          let list_value = [];
          for (let i = 0, len = this.dataList.length; i < len; i++) {
            list_value.push(this.dataList[i].value);
            list_name.push(this.dataList[i]._id);
          }
          this.dataList_name = list_name;
          this.dataList_value = list_value;
        })
        .catch((err) => {
          this.$message.error("发送失败！");
          console.log(err);
        });
    },

    async floorGraph() {
      await this.$app
        .callFunction({
          name: "floorGraph",
        })
        .then((res) => {
          console.log(res);
          this.dataList_floor = res.result.list;
          let list_name_floor = [];
          let list_value_floor = [];
          for (let i = 0, len = this.dataList_floor.length; i < len; i++) {
            list_value_floor.push(this.dataList_floor[i].value);
            list_name_floor.push(this.dataList_floor[i]._id);
          }
          this.dataList_name_floor = list_name_floor;
          this.dataList_value_floor = list_value_floor;
        })
        .catch((err) => {
          this.$message.error("发送失败！");
          console.log(err);
        });
    },

    async PICGraph() {
      await this.$app
        .callFunction({
          name: "PICGraph",
        })
        .then((res) => {
          console.log(res);
          this.dataList_PIC = res.result.list;
          let list_name_PIC = [];
          let list_value_PIC = [];
          for (let i = 0, len = this.dataList_PIC.length; i < len; i++) {
            list_value_PIC.push(this.dataList_PIC[i].value);
            list_name_PIC.push(this.dataList_PIC[i]._id);
          }
          this.dataList_name_PIC = list_name_PIC;
          this.dataList_value_PIC = list_value_PIC;
        })
        .catch((err) => {
          this.$message.error("发送失败！");
          console.log(err);
        });
    },

    async categoryGraph() {
      await this.$app
        .callFunction({
          name: "categoryGraph",
        })
        .then((res) => {
          console.log(res);
          this.dataList_category = res.result.list;
          let list = [];
          for (let i = 0, len = this.dataList_category.length; i < len; i++) {
            let event1 = {};
            event1["name"] = this.dataList_category[i]._id;
            event1["value"] = this.dataList_category[i].value;
            event1 = JSON.parse(JSON.stringify(event1));
            list.push(event1);
          }
          this.dataList_category = list;
        })
        .catch((err) => {
          this.$message.error("发送失败！");
          console.log(err);
        });
    },

    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let mychart = echarts.init(this.$refs.chart);

      mychart.showLoading({
        text: "数据正在努力加载...",
        textStyle: { fontSize: 30, color: "#444" },
        effectOption: { backgroundColor: "rgba(0, 0, 0, 0)" },
      });

      setTimeout(() => {
        // setOption前隐藏loading事件
        mychart.hideLoading();
        // 绘制图表
        mychart.setOption({
          title: { text: "店铺状态" },
          tooltip: {},
          xAxis: {
            data: this.dataList_name,
          },
          yAxis: {},
          series: [
            {
              name: "数量",
              type: "bar",
              label: {
                normal: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "black",
                  },
                },
              },
              data: this.dataList_value,
            },
          ],
        });
      }, 2000);
    },

    drawLine1() {
      // 基于准备好的dom，初始化echarts实例
      let mychart = echarts.init(this.$refs.chart1);
      mychart.showLoading({
        text: "数据正在努力加载...",
        textStyle: { fontSize: 30, color: "#444" },
        effectOption: { backgroundColor: "rgba(0, 0, 0, 0)" },
      });

      setTimeout(() => {
        // setOption前隐藏loading事件
        mychart.hideLoading();
        // 绘制图表
        mychart.setOption({
          title: { text: "店铺分布" },
          tooltip: {},
          xAxis: {
            data: this.dataList_name_floor,
          },
          yAxis: {},
          series: [
            {
              name: "数量",
              type: "bar",
              label: {
                normal: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "black",
                  },
                },
              },
              data: this.dataList_value_floor,
            },
          ],
        });
      }, 2000);
    },

    drawLine2() {
      // 基于准备好的dom，初始化echarts实例
      let mychart = echarts.init(this.$refs.chart2);
      mychart.showLoading({
        text: "数据正在努力加载...",
        textStyle: { fontSize: 30, color: "#444" },
        effectOption: { backgroundColor: "rgba(0, 0, 0, 0)" },
      });

      setTimeout(() => {
        // setOption前隐藏loading事件
        mychart.hideLoading();
        // 绘制图表
        mychart.setOption({
          title: {
            text: "业态分布",
            left: "center",
          },
          tooltip: {
            trigger: "item",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [
            {
              name: "业态",
              type: "pie",
              radius: "50%",
              data: this.dataList_category,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        });
      }, 2000);
    },

    drawLine3() {
      // 基于准备好的dom，初始化echarts实例
      let mychart = echarts.init(this.$refs.chart3);

      mychart.showLoading({
        text: "数据正在努力加载...",
        textStyle: { fontSize: 30, color: "#444" },
        effectOption: { backgroundColor: "rgba(0, 0, 0, 0)" },
      });

      setTimeout(() => {
        // setOption前隐藏loading事件
        mychart.hideLoading();
        // 绘制图表
        mychart.setOption({
          title: { text: "装修组PIC" },
          tooltip: {},
          xAxis: {
            data: this.dataList_name_PIC,
          },
          yAxis: {},
          series: [
            {
              name: "数量",
              type: "bar",
              label: {
                normal: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "black",
                  },
                },
              },
              data: this.dataList_value_PIC,
            },
          ],
        });
      }, 2000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#main {
  width: 600px;
  height: 400px;
  /* background-color:black;  */
  margin: 0 auto;
  /* background-color: grey; */
}
</style>

