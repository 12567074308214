import Vue from 'vue'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import Welcome from '../components/dashboard/Welcome.vue'
import Users from '../components/Users.vue'
import Inspection from '../components/Inspection.vue'
import Logs from '../components/Logs.vue'
import Files from '../components/Files.vue'
import Reservation from '../components/Reservation.vue'
import guest_user from '../components/guest_user.vue'
import Table from '../components/Table.vue'
import Map from '../components/Map.vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  {
    path: '/home',
    component: Home,
    redirect: "/welcome",
    children: [{ path: "/welcome", component: Welcome }, 
    { path: "/users", component: Users }, 
    { path: "/inspection", component: Inspection}, 
    { path: "/logs", component: Logs},
    { path: '/files', component: Files},
    { path: '/reservation', component: Reservation},
    { path: '/guest_user', component: guest_user},
    { path: '/table', component: Table },
    { path: '/map', component: Map }]
  }
]

const router = new VueRouter({
  routes
})

//挂在路由导航守卫
router.beforeEach((to, from, next) => {
  //to 将要访问的路径
  //from 代表从哪个路径跳转而来
  //next 是一个函数，代表放行
  //next（）放行， next（‘/login’）强制跳转
  if (to.path === '/login') return next();
  const tokenStr = window.sessionStorage.getItem('access_token_clouddatabase-3niwe')
  if (!tokenStr) return next('/login')
  next()
})
export default router
