<template>
  <div>
    <div class="card-panel-text">完成消项整改百分比</div>
    <dv-water-level-pond :config="config" style="width: 200px; height: 300px" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {},
      totalUncomplete: "",
      total: "",
    };
  },

  async created() {},

  async mounted() {
    await this.getUncomplete();
    let num = (1 - this.totalUncomplete / this.total) * 100;
    this.config = {
      data: [num.toFixed(2)],
      shape: "roundRect",
    };
    console.log(this.config.data);
  },

  methods: {
    async getUncomplete() {
      const db = this.$cloudbase.database();
      const _ = db.command;
      await this.$cloudbase
        .database()
        .collection("image")
        .where({
          zhenggaixiangmu: _.neq(""),
          complete: _.eq("").or(_.eq("no")).or(_.eq("[]")),
        })
        .count()
        .then((res) => {
          console.log("totalUncomplete", res);
          this.totalUncomplete = res.total;
        });

      await this.$cloudbase
        .database()
        .collection("image")
        .where({
          zhenggaixiangmu: _.neq(""),
        })
        .count()
        .then((res) => {
          console.log("total", res);
          this.total = res.total;
        });
    },
  },
};
</script>

<style lang="less">
.card-panel-text {
  line-height: 18px;
  color: rgb(0, 0, 0);
  font-size: 20px;
  margin-bottom: 12px;
  align-items: center;
}
</style>