import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css'
import Cloudbase from '@cloudbase/vue-provider'
import dataV from '@jiaminghi/data-view'
import cloudbase from "@cloudbase/js-sdk"
import print from 'print-js'
import echarts from 'echarts'

const app = cloudbase.init({
  env: "clouddatabase-3niwe"
});
Vue.prototype.$app = app // 在原型上添加上tcb-js-sdk实例

Vue.use(dataV)

Vue.config.productionTip = false

Vue.use(Cloudbase, {
  env: 'clouddatabase-3niwe'
})

Vue.prototype.$echarts = echarts

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
