<template>
  <div class="login_container">
    <div class="login_box">
      <h2 class="title">工程组管理系统</h2>
      <el-form
        ref="loginFormRef"
        class="login_form"
        :model="loginForm"
        :rules="rules"
      >
        <el-form-item prop="username">
          <el-input
            prefix-icon="el-icon-user"
            v-model="loginForm.username"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            prefix-icon="el-icon-lock"
            v-model="loginForm.password"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    login() {
      const app = this.$cloudbase;
      this.$refs.loginFormRef.validate((valid) => {
        if (!valid) return;
        app
          .auth()
          .signInWithEmailAndPassword(
            this.loginForm.username,
            this.loginForm.password
          )
          .then((loginState) => {
            console.log(loginState._cache._storage);
            this.$router.push({
              path: "/home",
            });
            this.$message.success("登录成功");
          })
          .catch((err) => {
            this.$message.error("账号或密码错误");
          });
      });
    },
    reset() {
      this.$refs.loginFormRef.resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.login_container {
  background-color: #2b4b6b;
  height: 100%;
}

.login_box {
  width: 450px;
  height: 300px;
  background-color: white;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -85%);
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
}

.title {
  display: flex;
  justify-content: center;
}
</style>
